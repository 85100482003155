import { all, takeEvery, put, fork, select } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken, getForbidToken, clearForbidToken, getModules, getIdentity, clearModules, clearIdentity, getStores, clearStores, clearTopic, getTopic, getEnv, clearEnv, getMqttToken, clearMqttToken, getCustomAuth, clearCustomAuth, getCustomModule, clearCustomModule, getRoles, clearRoles, clearMerchantList, getMerchantList, clearSelectedMerchant, getSelectedMerchant, clearSelectedMerchantName, getSelectedMerchantName, clearDisabledModules, getDisabledModules } from '@iso/lib/helpers/utility';
import actions from './actions';
import mqttAction from '../mqtt/actions';
import trxAction from '../transactions/actions';
// import dashBoardAction from '../dashboard/actions';
import { get, fetch } from '@iso/lib/helpers/resApiRequestor';
import { pushDataToApp } from '@iso/lib/helpers/mobileAppCommKit';

const history = createBrowserHistory();
const apiUrl = process.env.REACT_APP_API_URL;
const systemId = process.env.REACT_APP_SYSTEM_ID;
let headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    try {
      yield put({
        type: actions.KICKED_OUT_CLEAR,
      });
      yield put({
        type: actions.CLEAR_ERROR,
      });
      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });
      // const token = false;
      // try {
      const apiResult = yield login(payload);
      if (apiResult) {
        const result = apiResult.data ? apiResult.data : apiResult;
        if (result.success) {
          const authValue = result.data.user.role.authorizations;
          let formattedAuthorization = [];
          let formattedModuleId = {};
          for (let i = 0; i < authValue.length; i++) {
            const element = authValue[i];
            formattedModuleId[element.moduleId] = element;
          }
          formattedAuthorization.push(formattedModuleId);
          const apiResultRoles = yield fetchAllRoles(result.data.token);
          const resultRoles = apiResultRoles.data;

          const moduleResult = yield fetchModules(result.data.token);
          const modResult = moduleResult.data;
          const disabledModulesResult = yield fetchDisabledModules(result.data.user.tenantId);
          const disabledModules = disabledModulesResult.data.success && disabledModulesResult.data.data.length > 0 ? disabledModulesResult.data.data : null;
          // const disabledModules = null;
          if (modResult.success) {
            let formattedModule = [];
            let formatteElement = {};
            let allMerchant = [];
            const modulesData = modResult.data.result[0].submodules;
            for (let i = 0; i < modulesData.length; i++) {
              const element = modulesData[i];
              formatteElement[element.alias] = element;
            }
            formattedModule.push(formatteElement);
            const storesResult = yield fetchStores(result.data.token, result.data.user.merchantId)
            if (storesResult.status === 200) {
              const role = result.data.user.role.role.label;
              let topic;
              if (role === 'Store Admin' || role === 'Store User') {
                topic = `/lambda-transaction-${storesResult.data[0].storeId}`;
              } else if (role === 'Merchant Admin') {
                topic = `/lambda-transaction-${result.data.user.merchantId}`;
              } else if (role === 'Administrator') {
                allMerchant = yield fetchAllMerchant(result.data.token);
                allMerchant = allMerchant.data.data.result;
              }
              yield put({
                type: actions.SUBMODULES,
                payload: modResult.data.result[0].submodules,
              });
              if (result.data.user.userSettings) {
                yield put({
                  type: actions.SHOW_KYC,
                });
              }
              yield put({
                type: actions.LOGIN_SUCCESS,
                token: result.data.token,
                identity: JSON.stringify(result.data.user),
                stores: storesResult.data ? JSON.stringify(storesResult.data) : null,
                modules: JSON.stringify(modResult.data.result[0].submodules),
                merchantId: result.data.user.merchantId,
                customAuthorizations: JSON.stringify(formattedAuthorization[0]),
                customModules: JSON.stringify(formattedModule[0]),
                roles: JSON.stringify(resultRoles.data),
                profile: 'Profile',
                topic: topic,
                env: result.data.env,
                merchantList: JSON.stringify(allMerchant),
                selectedMerchant: 'All Merchant',
                selectedMerchantName: 'All Merchant',
                disabledModules: disabledModules && disabledModules.length > 0 ? JSON.stringify(disabledModules) : null,
              });
              yield put({
                type: mqttAction.CONNECT,
              });
              if (role === 'Pre Registered') {
                yield put({
                  type: trxAction.LOAD_PROVINCE,
                });
              }
              yield put({
                type: actions.SET_LOADING,
                loading: false,
              });
            } else {
              yield put({
                type: actions.LOGIN_ERROR,
                error: 'Cannot get store information',
              });
            }
          }
        } else {
          if (result && result.message === 'Unauthorized') {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'INVALID_AUTH',
            });
          } else if (result && result.message === 'Account not verified') {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'NOT_VERIFIED',
            });
          } else {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'Login Error',
            });
          }

          yield put({
            type: actions.SET_LOADING,
            loading: false,
          });
        }
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          error: 'INVALID_AUTH',
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
      }
    } catch (e) {
      yield put({
        type: actions.LOGIN_ERROR,
        error: 'Login Error',
      });
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    }
    // } catch (e) {
    //   yield put({
    //     type: actions.LOGIN_ERROR,
    //     error: e.message.indexOf('Network') > -1 ? 'NETWORK_ERROR' : 'INVALID_AUTH',
    //   });
    //   yield put({
    //     type: actions.SET_LOADING,
    //     loading: false,
    //   });
    // }
  });
}
export function* signupRequest() {
  yield takeEvery('SIGNUP', function* ({ payload }) {
    yield put({
      type: actions.CLEAR_ERROR,
    });
    yield put({
      type: actions.SET_LOADING,
      loading: true,
    });
    // const token = false;
    try {
      const apiResult = yield signup(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
        yield put({
          type: actions.CLEAR_ERROR,
        });
        window.location.href = result.url;
      } else {
        yield put({
          type: actions.SIGNUP_ERROR,
          error: `Registration failed. ${result.message}`,
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
      }
    } catch (e) {
      yield put({
        type: actions.SIGNUP_ERROR,
        error: 'Registration failed',
      });
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    }
  });
}

export function* forgotPassword() {
  yield takeEvery('FORGOT_PASSWORD', function* ({ email }) {
    yield put({
      type: actions.CLEAR_ERROR,
    });
    yield put({
      type: actions.SET_LOADING,
      loading: true,
    });
    // const token = false;
    try {
      const apiResult = yield forgotPasswordReq(email);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FORGOT_ERROR,
          error: null,
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
        yield put({
          type: actions.FORGOT_PASSWORD_DONE,
          success: true,
        });
      } else {
        yield put({
          type: actions.FORGOT_PASSWORD_DONE,
          success: false,
        });
        yield put({
          type: actions.FORGOT_ERROR,
          error: result.data,
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
      }
    } catch (e) {
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    }
  });
}

export function* changeMerchant() {
  yield takeEvery('CHANGE_MERCHANT', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const token = state.Auth.idToken;
    const merchantId = role !== 'Administrator' ? state.Auth.identity.merchantId : payload.id;
    const storesResult = yield fetchStores(token, merchantId);
    if (storesResult.status === 200 || storesResult.status === 204) {
      yield put({
        type: actions.CHANGE_MERCHANT_SUCCESS,
        selectedMerchant: payload.id,
        selectedMerchantName: payload.name,
        stores: storesResult.data ? JSON.stringify(storesResult.data) : null,
      });
    }
  });
}

export function* resendRegistrationEmail() {
  yield takeEvery('RESEND_REGISTRATION_EMAIL', function* ({ payload }) {
    try {
      yield put({
        type: actions.SENDING_REGISTRATION_EMAIL,
      });
      const apiResult = yield resendSuccesRegEmail(payload);
      if (apiResult.status === 200) {
        yield put({
          type: actions.SENT_REGISTRATION_EMAIL,
          payload: 'Email successfully sent',
        });
      } else {
        yield put({
          type: actions.RESEND_REGISTRATION_EMAIL_FAILED,
          payload: 'Failed to send email',
        });
      }
    } catch (e) {
      yield put({
        type: actions.RESEND_REGISTRATION_EMAIL_FAILED,
        payload: 'Failed to resend email',
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('idToken', payload.token);
    yield localStorage.setItem('modules', payload.modules);
    yield localStorage.setItem('identity', payload.identity);
    yield localStorage.setItem('stores', payload.stores);
    yield localStorage.setItem('topic', payload.topic);
    yield localStorage.setItem('env', payload.env);
    yield localStorage.setItem('customAuthorizations', payload.customAuthorizations);
    yield localStorage.setItem('customModules', payload.customModules);
    yield localStorage.setItem('roles', payload.roles);
    yield localStorage.setItem('merchantList', payload.merchantList);
    yield localStorage.setItem('selectedMerchant', payload.selectedMerchant);
    yield localStorage.setItem('selectedMerchantName', payload.selectedMerchantName);
    yield localStorage.setItem('disabledModules', payload.disabledModules);
    pushDataToApp('idToken', payload.token);
    pushDataToApp('modules', payload.modules);
    pushDataToApp('identity', payload.identity);
    pushDataToApp('stores', payload.stores);
    pushDataToApp('topic', payload.topic);
    pushDataToApp('env', payload.env);
    pushDataToApp('customAuthorizations', payload.customAuthorizations);
    pushDataToApp('customModules', payload.customModules);
    pushDataToApp('roles', payload.roles);
    pushDataToApp('merchantList', payload.merchantList);
    pushDataToApp('selectedMerchant', payload.selectedMerchant);
    pushDataToApp('selectedMerchantName', payload.selectedMerchantName);
    pushDataToApp('disabledModules', payload.disabledModules);
    yield put({
      type: actions.RESET_SECOND_TIMER,
      secondTimer: 1800,
    });
  });
}

export function* changeMerchantSuccess() {
  yield takeEvery(actions.CHANGE_MERCHANT_SUCCESS, function* (payload) {
    yield localStorage.setItem('selectedMerchant', payload.selectedMerchant);
    yield localStorage.setItem('selectedMerchantName', payload.selectedMerchantName);
    yield localStorage.setItem('stores', payload.stores);
    pushDataToApp('selectedMerchant', payload.selectedMerchant);
    pushDataToApp('selectedMerchantName', payload.selectedMerchantName);
    pushDataToApp('stores', payload.stores);
    yield put({
      type: actions.RESET_SECOND_TIMER,
      secondTimer: 1800,
    });
  });
}

export function* subModuleLoad() {
  yield takeEvery(actions.SUBMODULES, function* (data) {
    yield localStorage.setItem('modules', typeof data.payload === 'string' ? JSON.parse(data.payload) : data.payload);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    yield clearModules();
    yield clearIdentity();
    yield clearStores();
    yield clearTopic();
    yield clearEnv();
    yield clearMqttToken();
    yield clearCustomAuth();
    yield clearCustomModule();
    yield clearForbidToken();
    yield clearRoles();
    yield clearMerchantList();
    yield clearSelectedMerchant();
    yield clearSelectedMerchantName();
    yield clearDisabledModules();
    yield put({
      type: actions.RESET_SECOND_TIMER,
      secondTimer: 0,
    });
    history.push('/');
  });
}
export function* kickedOutClear() {
  yield takeEvery(actions.KICKED_OUT_CLEAR, function* () {
    yield clearForbidToken();
  });
}
export function* checkAuthorization() {

  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const forbidToken = getForbidToken().get('forbidToken');
    const token = getToken().get('idToken');
    const modules = getModules().get('modules');
    const identity = getIdentity().get('identity');
    const stores = getStores().get('stores');
    const topic = getTopic().get('topic');
    const env = getEnv().get('env');
    const mqttToken = getMqttToken().get('mqttToken');
    const customAuthorizations = getCustomAuth().get('customAuthorizations');
    const customModules = getCustomModule().get('customModules');
    const roles = getRoles().get('roles');
    const merchantList = getMerchantList().get('merchantList');
    const selectedMerchant = getSelectedMerchant().get('selectedMerchant');
    const selectedMerchantName = getSelectedMerchantName().get('selectedMerchantName');
    const disabledModules = getDisabledModules().get('disabledModules');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        identity,
        modules,
        stores,
        profile: 'Profile',
        topic,
        env,
        customAuthorizations,
        customModules,
        roles,
        merchantList,
        selectedMerchant,
        selectedMerchantName,
        disabledModules,
      });
      yield put({
        type: actions.LOGIN_VALIDATOR,
        token: mqttToken,
      });
      yield put({
        type: mqttAction.CONNECT,
      });
      // yield put({
      //   type: actions.SUBMODULES,
      //   payload: JSON.parse(modules),
      // });
    }
    if (forbidToken) {
      yield put({
        type: actions.KICKED_OUT,
      });
      yield put({
        type: actions.LOGOUT,
      });
    }
  });
}

export function* getRedirectUrl() {
  yield takeEvery('FETCH_REDIRECT_URL', function* () {
    try {
      const apiResult = yield fetchRedirectUrl();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_REDIRECT_URL,
      });
      yield put({
        type: actions.FETCHED_REDIRECT_URL,
        redirectUrl: result.redirect_login,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_REDIRECT_URL_FAILED,
      });
    }
  });
}

export function* getResetPassUrl() {
  yield takeEvery('FETCH_RESET_PASSWORD_URL', function* () {
    try {
      const apiResult = yield fetchResetPassUrl();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_RESET_PASSWORD_URL,
      });
      yield put({
        type: actions.FETCHED_RESET_PASSWORD_URL,
        resetPassUrl: result,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_RESET_PASSWORD_URL_FAILED,
      });
    }
  });
}

function login(data) {
  // this.checkToken();
  return fetch(`${apiUrl}authenticate`, {
    headers: headers,
    method: 'POST',
    data,
  });
}

function signup(data) {
  // this.checkToken();
  return fetch(`${apiUrl}registrationbizaccounts/pre_register`, {
    headers: headers,
    method: 'POST',
    data,
  });
}

function fetchRedirectUrl() {
  return fetch(`${apiUrl}redirect_url/get_redirect_url`, {
    headers: headers,
    method: 'get',
  });
}

function fetchModules(token) {
  // this.checkToken();
  return get(`system/${systemId}/modules`, null, null, null, token);
}

function fetchStores(token, mid) {
  // this.checkToken();
  return get(`stores/ui/get_store_by_mid/${mid}`, null, null, null, token);
}

function fetchAllRoles(token) {
  // this.checkToken();
  return get(`roles/fetch/store_roles`, null, null, null, token);
}

function forgotPasswordReq(email) {
  // this.checkToken();
  return get(`/resettokens/get_id_by_email/${email}`);
}

function fetchAllMerchant(token) {
  // this.checkToken();
  return get(`merchants/get_all_merchants`, null, null, null, token);
}

function resendSuccesRegEmail(data) {
  return fetch(`${apiUrl}registrationbizaccounts/resend_email`, {
    headers: headers,
    method: 'POST',
    data,
  });
}

function fetchResetPassUrl() {
  return fetch(`${apiUrl}redirect_url/get_reset_password_url`, {
    headers: headers,
    method: 'get',
  });
}

function fetchDisabledModules(id) {
  // this.checkToken();
  return get(`disablemodules/get_by_tenant/${id}`);
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(signupRequest),
    fork(forgotPassword),
    fork(loginSuccess),
    fork(subModuleLoad),
    fork(loginError),
    fork(logout),
    fork(kickedOutClear),
    fork(changeMerchant),
    fork(changeMerchantSuccess),
    fork(getRedirectUrl),
    fork(resendRegistrationEmail),
    fork(getResetPassUrl),
  ]);
}
