import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import authActions from '../auth/actions';
// import mqttActions from '../mqtt/actions';
import { get, post, PUT, DELETE } from '@iso/lib/helpers/resApiRequestor';
import { AuthorizedGet, AuthorizedPut } from '@iso/lib/helpers/resApiRequestorAuth';
// import dummyTransactions from './dummyData'
import * as _ from 'lodash';
import { pushDataToApp } from '@iso/lib/helpers/mobileAppCommKit';
const systemId = process.env.REACT_APP_SYSTEM_ID;

export function* search() {
  yield takeEvery('SEARCH', function* ({ payload }) {
    try {
      if (payload.keyword) {
        yield put({
          type: actions.START_SEARCH,
          keyword: payload.keyword,
        });
        yield put({
          type: actions.LOADING_TRANSACTIONS,
        });
        const apiResult = yield searchRequest(payload);
        const result = apiResult.data;
        yield put({
          type: actions.TRANSACTIONS_LOADED,
          transactions: result.data,
          scrollId: result.scrollId,
          transactionCount: result.totalCount,
        });
        // yield put({
        //   type: actions.SET_CURRENT_TRANSACTION,
        //   payload: result.data[0],
        // });
        yield put({
          type: actions.END_SEARCH,
          keyword: payload.keyword,
          searchValue: result.data[0],
        });
      }
    } catch (error) {
      // yield delay(5000);
      yield put({
        type: actions.END_SEARCH,
        keyword: null,
      });
    }
  });
}
export function* saveTag() {
  yield takeEvery('SAVE_TAG', function* ({ payload }) {
    const state = yield select();
    const currentTransaction = state.Transactions.currentTransaction;
    try {
      yield put({
        type: actions.TAG_SAVING,
      });
      const apiResult = yield saveTagRequest(payload, currentTransaction.orgrequestid);
      const result = apiResult.data;
      yield put({
        type: actions.TAG_ADDED,
        id: result.data.Id,
        label: result.data.tagLabel,
        color: result.data.tagColor,
      });
      yield put({
        type: actions.MENU_EVENT_TRIGGERING,
        triggering: false,
      });
    } catch (error) {
      yield put({
        type: actions.TAG_ADD_FAILED,
      });
    }
  });
}
export function* moveTag() {
  yield takeEvery('MOVE_TAG', function* ({ payload }) {
    const state = yield select();
    const currentTransaction = state.Transactions.currentTransaction;
    try {
      yield put({
        type: actions.TAG_MOVING,
      });
      const result = yield moveTagRequest(payload, currentTransaction.orgrequestid);
      const apiResult = result.data;
      if (apiResult.success) {
        yield put({
          type: actions.TAG_MOVED,
        });
        yield put({
          type: actions.MENU_EVENT_TRIGGERING,
          triggering: false,
        });
      } else {
        yield put({
          type: actions.TAG_FOLDER_MESSAGE,
          payload: apiResult.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.TAG_ADD_FAILED,
      });
    }
  });
}

export function* saveFolder() {
  yield takeEvery('SAVE_FOLDER', function* ({ payload }) {
    const state = yield select();
    const currentTransaction = state.Transactions.currentTransaction;
    try {
      yield put({
        type: actions.FOLDER_SAVING,
      });
      const apiResult = yield saveFolderRequest(payload, currentTransaction.orgrequestid);
      const result = apiResult.data;
      yield put({
        type: actions.FOLDER_ADDED,
        id: result.data.Id,
        label: result.data.folderLabel
      });
      yield put({
        type: actions.MENU_EVENT_TRIGGERING,
        triggering: false,
      });
    } catch (error) {
      yield put({
        type: actions.FOLDER_ADD_FAILED,
      });
    }
  });
}
export function* moveFolder() {
  yield takeEvery('MOVE_FOLDER', function* ({ payload }) {
    const state = yield select();
    const currentTransaction = state.Transactions.currentTransaction;
    const transactions = state.Transactions.transactions;
    const view = state.Transactions.view;
    try {
      yield put({
        type: actions.FOLDER_MOVING,
      });
      const result = yield moveFolderRequest(payload, currentTransaction.orgrequestid);
      const apiResult = result.data;
      if (apiResult.success) {
        yield put({
          type: actions.MENU_EVENT_TRIGGERING,
          triggering: false,
        });
        yield put({
          type: actions.FOLDER_MOVED,
        });
        if (view === 'Folder') {
          // use this code to remove transaction that is move to anothher folder
          const updatedTrx = _.reject(transactions.data, { requestid: currentTransaction.orgrequestid });
          yield put({
            type: actions.TRANSACTIONS_LOADED,
            transactions: updatedTrx.length > 0 ? updatedTrx : [],
          });
          // yield put({
          //   type: actions.SET_CURRENT_TRANSACTION,
          //   payload: updatedTrx[0],
          // });
        }
      } else {
        yield put({
          type: actions.TAG_FOLDER_MESSAGE,
          payload: apiResult.message,
        });
      }
      // yield put({
      //   type: actions.FOLDER_ADDED,
      //   id: toPascalCase(result.data.Id),
      //   label: result.data.folderLabel
      // });
    } catch (error) {
      yield put({
        type: actions.FOLDER_ADD_FAILED,
      });
    }
  });
}
export function* updateFolder() {
  yield takeEvery('UPDATE_FOLDER', function* ({ payload }) {
    try {
      yield put({
        type: actions.FOLDER_UPDATING,
      });
      const apiResult = yield updateFolderRequest(payload);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_FOLDERS_TRANSACTION,
        customFolders: result.data,
      });
      yield put({
        type: actions.MENU_EVENT_TRIGGERING,
        triggering: false,
      });
    } catch (error) {
      yield put({
        type: actions.FOLDER_UPDATING_FAILED,
      });
    }
  });
}
export function* updateTag() {
  yield takeEvery('UPDATE_TAG', function* ({ payload }) {
    try {
      yield put({
        type: actions.TAG_UPDATING,
      });
      const apiResult = yield updateTagRequest(payload);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_TAGS_TRANSACTION,
        tags: result.data,
      });
      yield put({
        type: actions.MENU_EVENT_TRIGGERING,
        triggering: false,
      });
    } catch (error) {
      yield put({
        type: actions.TAG_UPDATING_FAILED,
      });
    }
  });
}
export function* fetchFolders() {
  yield takeEvery('LOAD_FOLDERS', function* () {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    try {
      yield put({
        type: actions.LOADING_FOLDERS,
      });
      const apiResult = yield fetchFoldersRequest(merchantId);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_FOLDERS_TRANSACTION,
        customFolders: result.data,
      });
      yield put({
        type: actions.LOADING_FOLDERS,
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_FOLDERS_FAILED,
      });
    }
  });
}
export function* fetchTags() {
  yield takeEvery('LOAD_TAGS', function* () {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const userId = state.Auth.identity.Id;
    try {
      yield put({
        type: actions.LOADING_TAGS,
      });
      const apiResult = yield fetchTagsRequest(merchantId, userId);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_TAGS_TRANSACTION,
        tags: result.data,
      });
      yield put({
        type: actions.LOADING_TAGS,
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_TAGS_FAILED,
      });
    }
  });
}
export function* deleteFolder() {
  yield takeEvery('DELETE_FOLDER', function* ({ payload }) {
    try {
      yield put({
        type: actions.FOLDER_DELETING,
      });
      const apiResult = yield deleteFolderRequest(payload.id);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_FOLDERS_TRANSACTION,
        customFolders: result.data,
      });
      yield put({
        type: actions.FOLDER_DELETED,
      });
    } catch (error) {
      // yield delay(5000); // remove this
      yield put({
        type: actions.FOLDER_DELETE_FAILED,
      });
    }
  });
}
export function* deleteTag() {
  yield takeEvery('DELETE_TAG', function* ({ payload }) {
    const state = yield select();
    const userId = state.Auth.identity.Id;
    try {
      yield put({
        type: actions.TAG_DELETING,
      });
      const apiResult = yield deleteTagRequest(payload.id, userId);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_TAGS_TRANSACTION,
        tags: result.data,
      });
      yield put({
        type: actions.TAG_DELETED,
      });
    } catch (error) {
      // yield delay(5000); // remove this
      yield put({
        type: actions.TAG_DELETE_FAILED,
      });
    }
  });
}

export function* loadAllTransactions() {
  yield takeEvery('LOAD_ALL_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const loadingTransactions = state.Transactions.loadingTransactions;
    const selectedMerchant = role === 'Administrator' ? state.Auth.selectedMerchant : null;
    const statusCode = role === 'Administrator' ? payload !== 'All' ? [payload] : selectedMerchant !== 'All Merchant' ? payload : ['gr033', 'gr001', 'gr018', 'gr003', 'gr152'] : payload;
    // merchantList = merchantList.sort((a, b) => (a.legalName > b.legalName) ? 1 : ((b.legalName > a.legalName) ? -1 : 0));
    const merchantList = state.Auth.merchantList;
    const newMids = merchantList.map(v => v.merchantId.toLowerCase());
    // const merchantId = state.Auth.identity.merchantId;
    if (!loadingTransactions) {
      try {
        yield put({
          type: actions.LOADING_TRANSACTIONS,
        });
        const apiResult = yield loadAllTransactionRequest(statusCode, selectedMerchant, newMids);
        const result = apiResult.data;
        yield put({
          type: actions.TRANSACTIONS_LOADED,
          transactions: result.data.length > 0 ? result.data : [],
          scrollId: result.scrollId,
          transactionCount: result.totalCount,
        });
        // yield put({
        //   type: actions.SET_CURRENT_TRANSACTION,
        //   payload: result.data[0],
        // });
      } catch (error) {
        yield put({
          type: actions.LOAD_TRANSACTIONS_FAILED,
        });
      }
    }
  });
}
export function* loadMoreTransactions() {
  yield takeEvery('LOAD_MORE_TRANSACTIONS', function* ({ payload }) {
    const state = yield select();
    const loadingMoreTransactions = state.Transactions.loadingMoreTransactions;
    const transactionCount = state.Transactions.transactionCount;
    if (!loadingMoreTransactions) {
      try {
        yield put({
          type: actions.LOADING_MORE_TRANSACTIONS,
        });
        const apiResult = yield fetchMoreRequest(payload.scrollId);
        const result = apiResult.data;
        const finalData = payload.transactions.data.concat(result.data);
        yield put({
          type: actions.TRANSACTIONS_LOADED,
          transactions: finalData.length > 0 ? finalData : [],
          scrollId: payload.scrollId,
          transactionCount: transactionCount,
        });
      } catch (error) {
        // yield delay(3000); // remove this
        yield put({
          type: actions.LOAD_TRANSACTIONS_FAILED,
        });
      }
    }
  });
}


export function* fetchEsTrxByStoreId() {
  yield takeEvery('LOAD_ES_TRX_BY_STORE', function* ({ payload }) {
    try {
      yield put({
        type: actions.LOADING_TRANSACTIONS
      });
      const apiResult = yield fetchEsTrxRequest(payload.storeId);
      const result = apiResult.data;
      yield put({
        type: actions.TRANSACTIONS_LOADED,
        transactions: result.data.length > 0 ? result.data : [],
        scrollId: result.scrollId,
        transactionCount: result.totalCount,
      });
      // yield put({
      //   type: actions.SET_CURRENT_TRANSACTION,
      //   payload: result.data[0],
      // });
    } catch (error) {
      yield put({
        type: actions.LOADING_ES_TRX_ERROR,
      });
    }
  });
}
export function* fetchEsTrxByTypeId() {
  yield takeEvery('LOAD_ES_BY_TYPE_ID', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    try {
      yield put({
        type: actions.LOADING_TRANSACTIONS
      });
      const apiResult = yield fetchEsTrxByTypeIdRequest(payload, merchantId);
      const result = apiResult.data;
      yield put({
        type: actions.TRANSACTIONS_LOADED,
        transactions: result.data.length > 0 ? result.data : [],
        scrollId: result.scrollId,
        transactionCount: result.totalCount,
      });
      // yield put({
      //   type: actions.SET_CURRENT_TRANSACTION,
      //   payload: result.data[0],
      // });
    } catch (error) {
      yield put({
        type: actions.LOADING_ES_TRX_ERROR,
      });
    }
  });
}

export function* postRemarks() {
  yield takeEvery('POST_REMARKS', function* ({ payload }) {
    const state = yield select();
    const postingRemarks = state.Transactions.remarksPosting;
    const user = state.Auth.identity.username;
    const remarks = payload.remarks;
    payload.remarks = `#USER##${user}##USER#${payload.remarks}`;
    if (!postingRemarks) {
      try {
        yield put({
          type: actions.REMARKS_POSTING,
        });
        // yield delay(5000); // remove this
        const apiResult = yield saveRemarks(user, remarks, payload.request_id);
        yield put({
          type: actions.REMARKS_POSTED,
          requestId: payload.request_id,
          remarks: payload.remarks,
          // date: '2020-09-09 15:00:00' // change this to posting date
          date: apiResult.data
        })
      } catch (error) {
        // yield delay(3000); // remove this
        yield put({
          type: actions.REMARKS_POST_FAILED,
        });
      }
    }
  });
}

export function* issueRefund() {
  yield takeEvery('ISSUE_REFUND', function* ({ payload }) {
    const state = yield select();
    const postingRemarks = state.Transactions.issuingRefund;
    const generate_request = generateReqIdRefund()
    const reqId = payload.refund_id ? payload.refund_id : payload.requestid;
    if (!postingRemarks) {
      try {
        yield put({
          type: actions.ISSUING_REFUND,
        });
        const apiResult = yield refundRequest(
          reqId,
          generate_request,
          payload.amount,
          payload.requestid,
          payload.pchannel,
          '192.168.1.1',
          'https://webhook.site/df914f53-eb8d-44a4-b3f3-fc481c548f09',
          payload.responseurl);
        const result = apiResult.data;
        yield put({
          type: actions.REFUND_MESSAGE,
          payload: result.response_message,
          refundCode: result.response_code,
        });
        yield put({
          type: actions.REFUND_ISSUED,
          requestId: payload.requestid,
          responseMessage: result.response_message,
          refundCode: result.response_code,
        })
      } catch (error) {
        yield put({
          type: actions.ISSUE_REFUND_FAILED,
        });
      }
    }
  });
}

export function* downloadTrxPdf() {
  yield takeEvery('DOWNLOAD_TRANSACTION_PDF', function* ({ payload }) {
    try {
      yield put({
        type: actions.DOWNLOADING_TRANSACTION_PDF,
      });
      const result = yield fetchTrxPdf(payload);
      if (result.status !== 200) {
        yield put({
          type: actions.DOWNLOAD_TRANSACTION_PDF_FAILED,
          message: 'Failed to download transaction.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_TRANSACTION_PDF_FAILED,
        message: 'Failed to download transaction.',
      });
    }
  });
}

function generateReqIdRefund() {
  let id = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  for (let i = 0; i < 10; i++) {
    id += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return `REFUND${id}`;
}

export function* mqttIncommingTrx() {
  yield takeEvery('MQTT_INCOMMING_TRX', function* ({ payload }) {
    try {
      yield put({
        type: actions.LOADING_TRANSACTIONS,
      });
      yield put({
        type: actions.MQTT_TRX,
        payload: null,
      });
      yield put({
        type: actions.TRANSACTIONS_LOADED,
        transactions: payload,
      });
    } catch (error) {
      // yield delay(5000); // remove this
      yield put({
        type: actions.LOAD_TRANSACTIONS_FAILED,
      });
    }
  });
}
// export function* setView() {
//   yield takeEvery('SET_VIEW', function* ({ payload }) {
//     try {
//       yield put({
//         type: actions.SETTING_VIEW,
//         view: payload,
//       });
//     } catch (error) {
//       yield put({
//         type: actions.CONNECT_FAILED,
//       });
//     }
//   });
// }

function refundRequest(org_trxid, request_id, amount, biz_reqid, pchannel, ip_address, notification_url, response_url) {
  const data = {
    org_trxid,
    request_id,
    amount,
    biz_reqid,
    pchannel,
    ip_address,
    notification_url,
    response_url
  };

  return post(`refund`, data);
}

export function* loadStoreAssets() {
  yield takeEvery('LOAD_STORE_INVENTORY', function* ({ payload }) {
    // const state = yield select();
    // const merchantId = state.Auth.identity.merchantId;
    try {
      yield put({
        type: actions.RESET_STORE_INVENTORY
      });

      yield put({
        type: actions.RESET_ALLOWED_PCHANNEL
      });
      yield put({
        type: actions.LOADING_STORE_INVENTORY
      });
      const apiResult = yield fetchStoreInventory(payload.storeId);
      const result = apiResult.data;
      if (apiResult.data.success) {
        yield put({
          type: actions.STORE_INVENTORY_LOADED,
          inventory: result.data.length > 0 ? result.data : [],
        });

        try {
          yield put({
            type: actions.LOADING_ALLOWED_PCHANNEL
          });
          const apiResult = yield fetchAllowedChannels(payload.storeId);
          const result = apiResult.data;
          yield put({
            type: actions.ALLOWED_PCHANNEL_LOADED,
            pchannels: result.data.length > 0 ? result.data : [],
          });
        } catch (err) {
          yield put({
            type: actions.LOAD_ALLOWED_PCHANNEL_FAILED,
          });
        }
      } else {
        yield put({
          type: actions.LOAD_STORE_INVENTORY_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.LOAD_STORE_INVENTORY_FAILED,
      });
    }
  });
}

export function* countStoreInventory() {
  yield takeEvery('COUNT_STORE_INVENTORY', function* ({ payload }) {
    // const state = yield select();
    // const merchantId = state.Auth.identity.merchantId;
    yield put({
      type: actions.COUNTING_STORE_INVENTORY,
    });
    try {
      const apiResult = yield fetchStoreInventory(payload.storeId);
      const result = apiResult.data;
      if (apiResult.data.success) {
        yield put({
          type: actions.COUNT_STORE_INVENTORY_RESULT,
          count: {
            storeId: payload.storeId,
            count: result.data.length,
          }
        });
      }
    } catch (error) {
    }
  });
}

export function* deleteFolderTxn() {
  yield takeEvery('DELETE_FOLDER_TRANSACTION', function* ({ payload, folderId }) {
    const state = yield select();
    const transactions = state.Transactions.transactions;
    const view = state.Transactions.view;
    try {
      yield put({
        type: actions.FOLDER_TRANSACTION_DELETING,
        paylod: null,
      });
      const apiResult = yield deleteFolderTxnRequest(payload.requestid, folderId);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FOLDER_TRANSACTION_DELETED,
          payload: `Folder Transaction Deleted!`,
        });
        if (view === 'Folder') {
          // use this code to remove transaction that is move to anothher folder
          const updatedTrx = _.reject(transactions.data, { requestid: payload.requestid });
          yield put({
            type: actions.TRANSACTIONS_LOADED,
            transactions: updatedTrx.length > 0 ? updatedTrx : [],
          });
          // yield put({
          //   type: actions.SET_CURRENT_TRANSACTION,
          //   payload: updatedTrx[0],
          // });
        }
      } else {
        yield put({
          type: actions.FOLDER_TRANSACTION_DELETE_FAILED,
        });
      }
    } catch (error) {
      // yield delay(5000); // remove this
      yield put({
        type: actions.FOLDER_TRANSACTION_DELETE_FAILED,
      });
    }
  });
}

export function* removeTransactionTag() {
  yield takeEvery('REMOVE_TXN_TAG', function* ({ payload, tag_id }) {
    const state = yield select();
    const transactions = state.Transactions.transactions;
    // const tags = state.Transactions.tags;
    const view = state.Transactions.view;
    try {
      yield put({
        type: actions.REMOVING_TXN_TAG,
        paylod: null,
      });
      const apiResult = yield removeTxnTagRequest(payload.requestid, tag_id);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.TXN_TAG_REMOVED,
          payload: `Transaction removed from tag!`,
        });
        if (view === 'Tag') {
          // use this code to remove transaction that is move to anothher folder
          const updatedTrx = _.reject(transactions.data, { requestid: payload.requestid });
          yield put({
            type: actions.TRANSACTIONS_LOADED,
            transactions: updatedTrx.length > 0 ? updatedTrx : [],
          });
          // yield put({
          //   type: actions.SET_CURRENT_TRANSACTION,
          //   payload: updatedTrx[0],
          // });
        }
      } else {
        yield put({
          type: actions.TXN_TAG_REMOVE_FAILED,
        });
      }
    } catch (error) {
      // yield delay(5000); // remove this
      yield put({
        type: actions.TXN_TAG_REMOVE_FAILED,
      });
    }
  });
}

export function* loadProvice() {
  yield takeEvery('LOAD_PROVINCE', function* () {
    try {
      yield put({
        type: actions.RESET_PROVINCE
      });
      yield put({
        type: actions.LOADING_PROVINCE
      });
      const apiResult = yield fetchProvince();
      const result = apiResult.data;
      yield put({
        type: actions.PROVINCE_LOADED,
        provinces: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_PROVINCE_FAILED,
      });
    }
  });
}
export function* loadProviceShipping() {
  yield takeEvery('LOAD_PROVINCE_S', function* () {
    try {
      yield put({
        type: actions.RESET_PROVINCE_S
      });
      yield put({
        type: actions.LOADING_PROVINCE_S
      });
      const apiResult = yield fetchProvince();
      const result = apiResult.data;
      yield put({
        type: actions.PROVINCE_LOADED_S,
        provinces: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_PROVINCE_FAILED_S,
      });
    }
  });
}
export function* loadCities() {
  yield takeEvery('LOAD_CITY', function* ({ payload }) {
    try {
      yield put({
        type: actions.RESET_CITY
      });
      yield put({
        type: actions.LOADING_CITY
      });
      const apiResult = yield fetchCity(payload.province);
      const result = apiResult.data;
      yield put({
        type: actions.CITY_LOADED,
        cities: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_CITY_FAILED,
      });
    }
  });
}
export function* loadCitiesShipping() {
  yield takeEvery('LOAD_CITY_S', function* ({ payload }) {
    try {
      yield put({
        type: actions.RESET_CITY_S
      });
      yield put({
        type: actions.LOADING_CITY_S
      });
      const apiResult = yield fetchCity(payload.province);
      const result = apiResult.data;
      yield put({
        type: actions.CITY_LOADED_S,
        cities: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_CITY_FAILED_S,
      });
    }
  });
}
export function* loadBrgys() {
  yield takeEvery('LOAD_BRGY', function* ({ payload }) {
    try {
      yield put({
        type: actions.RESET_BRGY
      });
      yield put({
        type: actions.LOADING_BRGY
      });
      const apiResult = yield fetchBrgy(payload.province, payload.city);
      const result = apiResult.data;
      yield put({
        type: actions.BRGY_LOADED,
        brgys: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_BRGY_FAILED,
      });
    }
  });
}
export function* loadBrgysShipping() {
  yield takeEvery('LOAD_BRGY_S', function* ({ payload }) {
    try {
      yield put({
        type: actions.RESET_BRGY_S
      });
      yield put({
        type: actions.LOADING_BRGY_S
      });
      const apiResult = yield fetchBrgy(payload.province, payload.city);
      const result = apiResult.data;
      yield put({
        type: actions.BRGY_LOADED_S,
        brgys: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_BRGY_FAILED_S,
      });
    }
  });
}
// export function* loadTempTransaction() {
//   yield takeEvery('LOAD_TEMP_TRANSACTION', function* ({ payload }) {
//     try {
//       yield put({
//         type: actions.LOADING_TEMP_TRANSACTION
//       });
//       const statusResult = yield checkTrxStatus(payload);
//       const apiResult = yield fetchTempTransaction(payload);
//       const result = apiResult.data;
//       if (result.success) {
//         const tempData = JSON.parse(result.data.tempTransaction);
//         const tempDataId = result.data.Id;
//         const apiStoreResult = yield fetchStoreData(tempData.storeId);
//         const storeResult = apiStoreResult.data;
//         if (storeResult.success) {
//           yield put({
//             type: actions.TEMP_TRANSACTION_LOADED,
//             data: JSON.parse(result.data.tempTransaction),
//             dataId: tempDataId,
//             storeData: storeResult.data,
//             statusResult: statusResult.data.data,
//             customerChoosePmethod: tempData.customerChoosePmethod,
//           });
//         }
//       } else {
//         yield put({
//           type: actions.LOAD_TEMP_TRANSACTION_FAILED,
//         });
//       }
//     } catch (error) {
//       yield put({
//         type: actions.LOAD_TEMP_TRANSACTION_FAILED,
//       });
//     }
//   });
// }
export function* loadTempTransaction() {
  yield takeEvery('LOAD_TEMP_TRANSACTION', function* ({ payload }) {
    try {
      // yield put({
      //   type: actions.RESET_BRGY_S
      // });
      yield put({
        type: actions.LOADING_TEMP_TRANSACTION
      });
      const statusResult = yield checkTrxStatus(payload);
      if (statusResult.data.success) {
        if (statusResult.data.data.response_code !== 'GR033') {
          yield put({
            type: actions.TEMP_TRANSACTION_LOADED,
            data:  null,
            dataId: null,
            storeData: null,
            statusResult: statusResult.data.data,
            customerChoosePmethod: null,
          });
        } else {
          const apiResult = yield fetchTempTransaction(payload);
          const result = apiResult.data;
          if (result.success) {
            const tempData = JSON.parse(result.data.tempTransaction);
            const tempDataId = result.data.Id;
            const apiStoreResult = yield fetchStoreData(tempData.storeId);
            const storeResult = apiStoreResult.data;
            if (storeResult.success) {
              yield put({
                type: actions.TEMP_TRANSACTION_LOADED,
                data: JSON.parse(result.data.tempTransaction),
                dataId: tempDataId,
                storeData: storeResult.data,
                statusResult: statusResult.data.data,
                customerChoosePmethod: tempData.customerChoosePmethod,
              });
            }
          } else {
            yield put({
              type: actions.LOAD_TEMP_TRANSACTION_FAILED,
            });
          }
        }
      } else {
        yield put({
          type: actions.LOAD_TEMP_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.LOAD_TEMP_TRANSACTION_FAILED,
      });
    }
  });
}
export function* submitTempTrx() {
  yield takeEvery('SUBMIT_TEMP_TRX', function* ({ payload }) {
    try {
      yield put({
        type: actions.SUBMITTING_TEMP_TRX
      });
      let apiResult;
      if (payload.directPush) {
        const data = payload.data;
        delete data.customerChoosePmethod
        delete data.customerInputInfo
        apiResult = yield submitKrakenTransaction(data);
      } else {
        apiResult = yield submitTempTransaction(payload.data);
      }
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.TEMP_TRX_SUBMITTED,
          payload: result.data,
        });
        // let url;
        // if (payload.directPush) {
        //   url = result.data.payment_action_info;
        // } else {
        //   url = result.data.paymentLinkUrl;
        // }
        // yield put({
        //   type: actions.URL,
        //   payload: url,
        // });
      } else {
        yield put({
          type: actions.SUBMIT_TEMP_TRX_FAILED,
        });
        yield put({
          type: actions.TEMP_ERROR_MESSAGE,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SUBMIT_TEMP_TRX_FAILED,
      });
    }
  });
}
export function* redirectCustomerSubmitTrx() {
  yield takeEvery('SUBMIT_TRX_REDIRECT_CUSTOMER', function* ({ payload }) {
    const state = yield select();
    const trxTempId = state.Transactions.tempTransactionId;
    const customerChoosePmethod = state.Transactions.customerChoosePmethod;
    try {
      yield put({
        type: actions.SUBMITTING_REDIRECT_CUSTOMER_TRX
      });
      const apiResult = yield submitKrakenTransactionCustomer(payload);
      const updatedTempTrx = yield saveTransactionCustomer(trxTempId, payload, customerChoosePmethod);
      const result = apiResult.data;
      const resultUpdatedTemp = updatedTempTrx.data;
      if (resultUpdatedTemp.success) {
        yield put({
          type: actions.UPDATED_TRX_TEMP,
        });
      }
      if (result.success) {
        yield put({
          type: actions.REDIRECT_CUSTOMER_TRX_SUBMITTED,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.SUBMIT_TEMP_TRX_FAILED,
        });
        yield put({
          type: actions.TEMP_ERROR_MESSAGE,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SUBMIT_TEMP_TRX_FAILED,
      });
    }
  });
}
export function* getAccountThreshold() {
  yield takeEvery('GET_THRESHOLD', function* ({ payload }) {
    try {

      const state = yield select();
      const loading = state.Transactions.thresholdLoading;
      if (!loading) {
        yield put({
          type: actions.GETTING_THRESHOLD
        });

        const merchantId = state.Auth.identity.merchantId;
        const apiResult = yield getLimit(merchantId);

        const result = apiResult.data;
        if (result) {
          yield put({
            type: actions.GOT_THRESHOLD,
            payload: result,
          });
        } else {
          yield put({
            type: actions.GETTING_THRESHOLD_FAILED,
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_THRESHOLD_FAILED,
      });
    }
  });
}
export function* submitApplication() {
  yield takeEvery('SUBMIT_APPLICATION', function* ({ payload }) {
    try {
      yield put({
        type: actions.SUBMITTING_APPLICATION,
      });
      const apiResult = yield createFullyRegistered(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.APPLICATION_SUBMITTED,
          payload: true,
        });
        const moduleResult = yield fetchModules();
        const modResult = moduleResult.data;
        const authValue = result.data.role.authorizations;
        let formattedAuthorization = [];
        let formattedModuleId = {};
        for (let i = 0; i < authValue.length; i++) {
          const element = authValue[i];
          formattedModuleId[element.moduleId] = element;
        }
        formattedAuthorization.push(formattedModuleId);
        if (modResult.success) {
          let formattedModule = [];
          let formatteElement = {};
          const modulesData = modResult.data.result[0].submodules;
          for (let i = 0; i < modulesData.length; i++) {
            const element = modulesData[i];
            formatteElement[element.alias] = element;
          }
          formattedModule.push(formatteElement);
          yield put({
            type: authActions.SUBMODULES,
            payload: modResult.data.result[0].submodules,
          });

          const apiResultRoles = yield fetchAllRoles();
          const resultRoles = apiResultRoles.data;
          const storesResult = yield fetchStores(result.data.merchantId)
          if (storesResult.status === 200) {
            const role = result.data.role.role.label;
            let topic;
            if (role === 'Store Admin' || role === 'Store User') {
              topic = `/lambda-transaction-${storesResult.data[0].storeId}`;
            } else if (role === 'Merchant Admin') {
              topic = `/lambda-transaction-${result.data.merchantId}`;
            }
            yield put({
              type: authActions.FULLY_REGISTERED,
              identity: JSON.stringify(result.data),
              stores: storesResult.data ? JSON.stringify(storesResult.data) : null,
              modules: JSON.stringify(modResult.data.result[0].submodules),
              merchantId: result.data.merchantId,
              customAuthorizations: JSON.stringify(formattedAuthorization[0]),
              customModules: JSON.stringify(formattedModule[0]),
              roles: JSON.stringify(resultRoles.data),
              profile: 'Profile',
              topic: topic,
              preRegistered: false,
            });
            yield put({
              type: actions.APPLICATION_SUBMITTED,
              payload: true,
            });
            const module = JSON.stringify(modResult.data.result[0].submodules);
            const identity = JSON.stringify(result.data);
            const stores = storesResult.data ? JSON.stringify(storesResult.data) : null;
            const customAuthorizations = JSON.stringify(formattedAuthorization[0]);
            const customModules = JSON.stringify(formattedModule[0]);
            const roles = JSON.stringify(resultRoles.data);
            yield localStorage.setItem('modules', module);
            yield localStorage.setItem('identity', identity);
            yield localStorage.setItem('stores', stores);
            yield localStorage.setItem('topic', topic);
            yield localStorage.setItem('customAuthorizations', customAuthorizations);
            yield localStorage.setItem('customModules', customModules);
            yield localStorage.setItem('roles', roles);
            pushDataToApp('modules', module);
            pushDataToApp('identity', identity);
            pushDataToApp('stores', stores);
            pushDataToApp('topic', topic);
            pushDataToApp('customAuthorizations', customAuthorizations);
            pushDataToApp('customModules', customModules);
            pushDataToApp('roles', roles);
            // yield put({
            //   type: mqttActions.CONNECT,
            // });
          }
        }
      } else {
        yield put({
          type: actions.SUBMITTING_APPLICATION_ERROR,
          payload: result.message ? result.message : 'Error on saving!'
        });
        yield put({
          type: actions.SUBMITTING_APPLICATION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SUBMITTING_APPLICATION_FAILED,
      });
    }
  });
}

export function* submitTemporaryTransaction() {
  yield takeEvery('SUBMIT_TEMP_TRANSACTION', function* ({ payload }) {
    try {
      yield put({
        type: actions.SUBMITTING_TEMP_TRANSACTION,
      });
      const apiResult = yield submitTempTransaction(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.SUBMITTED_TEMP_TRANSACTION,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.SUBMIT_TEMP_TRANSACTION_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SUBMIT_TEMP_TRANSACTION_FAILED,
        payload: error,
      });
    }
  });
}

export function* getRedirectTrxStatus() {
  yield takeEvery('FETCH_TRX_STATUS', function* (payload) {
    try {
      const apiResult = yield checkTrxStatus(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_TRX_STATUS,
      });
      yield put({
        type: actions.FETCHED_TRX_STATUS,
        result: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_TRX_STATUS_FAILED,
      });
    }
  });
}

export function* searchAuditLogs() {
  yield takeEvery('SEARCH_AUDITLOGS', function* (payload) {
    try {
      const apiResult = yield searchAudit(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.SEARCHING_AUDITLOGS,
      });
      yield put({
        type: actions.SEARCHED_AUDITLOGS,
        result: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.SEARCH_AUDITLOGS_FAILED,
      });
    }
  });
}

export function* fetchMoreLogs() {
  yield takeEvery('LOAD_MORE_LOGS', function* (payload) {
    const state = yield select();
    const loadingMoreLogs = state.Transactions.loadingMoreLogs;
    if (!loadingMoreLogs) {
      try {
        yield put({
          type: actions.LOADING_MORE_LOGS,
        });
        const apiResult = yield loadMoreLogs(payload.payload.scroll_id);
        const result = apiResult.data;
        const finalResult = payload.payload.logs.data ? payload.payload.logs.data.concat(result.data) :
          payload.payload.logs.concat(result.data);
        yield put({
          type: actions.LOADED_MORE_LOGS,
          // result: result.data,
          result: finalResult,
        });
      } catch (error) {
        yield put({
          type: actions.LOAD_MORE_LOGS_FAILED,
        });
      }
    }
  });
}

export function* fetchTxnFees() {
  yield takeEvery('FETCH_TRANSACTION_FEE', function* (payload) {
    try {
      const apiResult = yield fetchFees(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_TRANSCTION_FEE,
      });
      yield put({
        type: actions.FETCHED_TRANSACTION_FEE,
        result: result,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_TRANSACTION_FEE_FAILED,
      });
    }
  });
}

export function* fetchPaymentFees() {
  yield takeEvery('FETCH_PAYMENT_FEES', function* () {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    try {
      yield put({
        type: actions.FETCHING_PAYMENT_FEES,
      });
      const apiResult = yield fetchPaymentFeesRequest(merchantId);
      const result = apiResult.data;
      const apiResultB = yield fetchMerchantPaymentFeesRequest(merchantId);
      const resultB = apiResultB.data;
      if (apiResult.status === 200) {
        yield put({
          type: actions.FETCH_PAYMENT_FEES_SUCCESS,
          result: result,
          payload: resultB.data,
        });
      } else {
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PAYMENT_FEES_FAILED,
      });
    }
  });
}


function fetchStores(mid) {
  // this.checkToken();
  return get(`stores/ui/get_store_by_mid/${mid}`);
}
function getLimit(mid) {
  // this.checkToken();
  return get(`payment_transactions/get_aggregated_report/${mid}`);
}
function fetchAllRoles() {
  // this.checkToken();
  return get(`roles/fetch/store_roles`);
}
function fetchModules() {
  // this.checkToken();
  return get(`system/${systemId}/modules`);
}

function fetchEsTrxRequest(storeId) {
  return get(`elastictransactions/fetch_transaction_by_store_id/${storeId}`);
}
function fetchEsTrxByTypeIdRequest(data, mid) {
  const finalData = {
    mid,
    type: data.type,
    type_id: data.tagId,
  };
  return post(`elastictransactions/fetch_transaction_by_req_and_mid`, finalData);
}
function searchRequest(data) {
  return post(`elastictransactions/new/search_transaction`, data);
}
function fetchMoreRequest(scrollId) {
  return get(`elastictransactions/load_more_transaction/${scrollId}`);
}
function fetchStoreInventory(storeId) {
  return AuthorizedGet(`inventories/get_product/ui/${storeId}`);
}
function fetchAllowedChannels(storeId) {
  return AuthorizedGet(`storechannels/get_pchannel_by_store_id/${storeId}`);
}
function fetchProvince() {
  return AuthorizedGet(`provinces/fetch/province_data`);
}
function fetchCity(province) {
  return AuthorizedGet(`provinces/fetch/city_data/${province}`);
}
function fetchBrgy(province, city) {
  return AuthorizedGet(`provinces/fetch/barangay/${province}_AND_${city}`);
}
function fetchTempTransaction(requestId) {
  return AuthorizedGet(`/temptransactions/get_temp_transactions/${requestId}`);
}
function checkTrxStatus(requestId) {
  return AuthorizedGet(`/payment_transactions/check_status/${requestId}`);
}
function fetchStoreData(storeId) {
  return AuthorizedGet(`/stores/get_store/${storeId}`);
}
function loadAllTransactionRequest(type, selectedMerchant, newMids) {
  const data = {
    type: type !== 'All' ? type : null,
    merchantId: selectedMerchant ? selectedMerchant : 'All Merchant',
    mids: newMids,
  };
  return post(`elastictransactions/fetch_transaction_by_mid_and_type`, data);
  // return get(`elastictransactions/fetch_transaction_by_merchant_id/${mid}`);
}
function saveFolderRequest(data, trxId) {
  data['transaction_id'] = trxId;
  return post(`transactionfolders/save/folder`, data);
}
function submitTempTransaction(payload) {
  return post(`temptransactions`, payload);
}
function submitKrakenTransaction(payload) {
  return post(`payments/request_to_kraken`, payload);
}
function submitKrakenTransactionCustomer(payload) { // used for redirect customer
  return post(`payments/request_to_kraken_customer`, payload);
}
function saveTransactionCustomer(id,payload,data) { // used for redirect customer
  if (!data) {
    payload.customerChoosePmethod = false;
  } else {
    payload.customerChoosePmethod = true;
  }
  const newPayload = JSON.stringify(payload);
  return AuthorizedPut(`temptransactions/update/temp_trx/${id}`, newPayload);
}
function moveFolderRequest(data, trxId) {
  data['transaction_id'] = trxId;
  return post(`transactionfoldercontents/move/folder`, data);
}
function moveTagRequest(data, trxId) {
  data['transaction_id'] = trxId;
  return post(`transactiontagcontents/move/tag`, data);
}
function updateFolderRequest(data) {
  return PUT(`transactionfolders/update/folder/${data.folder_id}`, data);
}
function fetchFoldersRequest(mid) {
  return get(`transactionfolders/fetch/folders/${mid}`);
}
// change the status to deleted
function deleteFolderRequest(id) {
  return PUT(`transactionfolders/delete/folder/${id}`);
}
// change the status to deleted
function deleteTagRequest(id, userId) {
  const data = {
    userId,
  };
  return PUT(`transactiontags/delete/tag/${id}`, data);
}

function updateTagRequest(data) {
  return PUT(`transactiontags/update/tag/${data.tag_id}`, data);
}
function fetchTagsRequest(mid, userId) {
  const data = {
    mid,
    userId,
  };
  return post(`transactiontags/fetch/tags/`, data);
}
function saveTagRequest(data, trxId) {
  data['transaction_id'] = trxId;
  return post(`transactiontags/save/tag`, data);
}
function saveRemarks(user, remarks, requestId) {
  const data = {
    user,
    remarks,
    requestId,
  }
  return post(`elastictransactions/add_remarks`, data);
}
function deleteFolderTxnRequest(transactionId, folderId) {
  return DELETE(`transactionfoldercontents/${folderId}_FLD_${transactionId}`);
}
function removeTxnTagRequest(transaction_id, tag_id) {
  // return DELETE(`transactiontagcontents/${tagId}_TAG_${transactionId}`);
  const data = {
    transaction_id,
    tag_id,
  };
  return DELETE(`transactiontagcontents/delete/tag`, data);
}
function createFullyRegistered(data) {
  return post(`registrationbizaccounts/create_fully_registered/`, data);
}
function fetchTrxPdf(payload) {
  // const data = {
  //   requestId: payload,
  // };
  return post(`elastictransactions/download_transaction_pdf`, payload);
}
function searchAudit(data) {
  return post(`auditlogs/search/transactions`, data);
}
function loadMoreLogs(scrollId) {
  return get(`auditlogs/load_more_logs/${scrollId}`);
}
function fetchFees(mid) {
  return get(`merchantsettings/fetch_fee_v2/${mid}`);
}
function fetchPaymentFeesRequest(mid) {
  return get(`merchantsettings/fetch_fee_v2/${mid}`);
}
function fetchMerchantPaymentFeesRequest(mid) {
  const data = {
    merchantId: mid
  }
  return post(`merchantpchannelfees/fetch/pchannel_fees/`, data);
}

export default function* rootSaga() {
  yield all([
    fork(search),
    fork(saveTag),
    fork(saveFolder),
    fork(moveFolder),
    fork(moveTag),
    fork(updateFolder),
    fork(updateTag),
    fork(fetchFolders),
    fork(fetchTags),
    fork(fetchEsTrxByStoreId),
    fork(fetchEsTrxByTypeId),
    fork(loadAllTransactions),
    fork(loadMoreTransactions),
    fork(deleteFolder),
    fork(deleteTag),
    fork(postRemarks),
    fork(issueRefund),
    fork(mqttIncommingTrx),
    // fork(setView),
    fork(loadStoreAssets),
    fork(loadProvice),
    fork(loadProviceShipping),
    fork(loadCities),
    fork(loadCitiesShipping),
    fork(loadBrgys),
    fork(loadBrgysShipping),
    fork(loadTempTransaction),
    fork(deleteFolderTxn),
    fork(submitTempTrx),
    fork(redirectCustomerSubmitTrx),
    fork(removeTransactionTag),
    fork(submitApplication),
    fork(getAccountThreshold),
    fork(countStoreInventory),
    fork(submitTemporaryTransaction),
    fork(getRedirectTrxStatus),
    fork(downloadTrxPdf),
    fork(searchAuditLogs),
    fork(fetchMoreLogs),
    fork(fetchTxnFees),
    fork(fetchPaymentFees),
  ]);
}