import { Map } from 'immutable';

export function clearToken() {
  localStorage.removeItem('idToken');
}

export function clearModules() {
  localStorage.removeItem('modules');
}

export function clearIdentity() {
  localStorage.removeItem('identity');
}

export function clearStores() {
  localStorage.removeItem('stores');
}
export function clearTopic() {
  localStorage.removeItem('topic');
}
export function clearEnv() {
  localStorage.removeItem('env');
}
export function clearMqttToken() {
  localStorage.removeItem('mqttToken');
}
export function clearCustomAuth() {
  localStorage.removeItem('customAuthorizations');
}
export function clearCustomModule() {
  localStorage.removeItem('customModules');
}
export function clearRoles() {
  localStorage.removeItem('roles');
}
export function clearForbidToken() {
  localStorage.removeItem('forbidToken');
}
export function clearMerchantList() {
  localStorage.removeItem('merchantList');
}
export function clearSelectedMerchant() {
  localStorage.removeItem('selectedMerchant');
}
export function clearSelectedMerchantName() {
  localStorage.removeItem('selectedMerchantName');
}
export function clearDisabledModules() {
  localStorage.removeItem('disabledModules');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('idToken');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getModules() {
  try {
    const modules = localStorage.getItem('modules');
    return new Map({ modules });
  } catch (err) {
    clearModules();
    return new Map();
  }
}

export function getForbidToken() {
  try {
    const forbidToken = localStorage.getItem('forbidToken');
    return new Map({ forbidToken });
  } catch (err) {
    clearModules();
    return new Map();
  }
}

export function getIdentity() {
  try {
    const identity = localStorage.getItem('identity');
    return new Map({ identity });
  } catch (err) {
    clearModules();
    return new Map();
  }
}

export function getStores() {
  try {
    const stores = localStorage.getItem('stores');
    return new Map({ stores });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getTopic() {
  try {
    const topic = localStorage.getItem('topic');
    return new Map({ topic });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getEnv() {
  try {
    const env = localStorage.getItem('env');
    return new Map({ env });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getMqttToken() {
  try {
    const mqttToken = localStorage.getItem('mqttToken');
    return new Map({ mqttToken });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getCustomAuth() {
  try {
    const customAuthorizations = localStorage.getItem('customAuthorizations');
    return new Map({ customAuthorizations });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getCustomModule() {
  try {
    const customModules = localStorage.getItem('customModules');
    return new Map({ customModules });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getRoles() {
  try {
    const roles = localStorage.getItem('roles');
    return new Map({ roles });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getMerchantList() {
  try {
    const merchantList = localStorage.getItem('merchantList');
    return new Map({ merchantList });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getSelectedMerchant() {
  try {
    const selectedMerchant = localStorage.getItem('selectedMerchant');
    return new Map({ selectedMerchant });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getSelectedMerchantName() {
  try {
    const selectedMerchantName = localStorage.getItem('selectedMerchantName');
    return new Map({ selectedMerchantName });
  } catch (err) {
    clearModules();
    return new Map();
  }
}
export function getDisabledModules() {
  try {
    const disabledModules = localStorage.getItem('disabledModules');
    return new Map({ disabledModules });
  } catch (err) {
    clearModules();
    return new Map();
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}
