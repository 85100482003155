const actions = {
  LOAD_DASHBOARD_DATA: 'LOAD_DASHBOARD_DATA',
  LOADING_DASHBOARD_DATA: 'LOADING_DASHBOARD_DATA',
  LOADED_DASHBOARD_DATA: 'LOADED_DASHBOARD_DATA',
  LOAD_DASHBOARD_ERROR: 'LOAD_DASHBOARD_ERROR',
  RESET_DASHBOARD_DATA: 'RESET_DASHBOARD_DATA',
  // RAFFLE ENTRY
  SEND_RAFFLE_ENTRY: 'SEND_RAFFLE_ENTRY',
  SENDING_RAFFLE_ENTRY: 'SENDING_RAFFLE_ENTRY',
  RAFFLE_ENTRY: 'RAFFLE_ENTRY',
  SENDING_FAILED_RAFFLE_ENTRY: 'SENDING_FAILED_RAFFLE_ENTRY',
  GET_WINNERS: 'GET_WINNERS',
  GETTING_WINNERS: 'GETTING_WINNERS',
  GOT_WINNERS: 'GOT_WINNERS',
  START_RAFFLE: 'START_RAFFLE',
  STARTING_RAFFLE: 'STARTING_RAFFLE',
  DONE_RAFFLE: 'DONE_RAFFLE',
  getPaymentCount: () => ({
    type: actions.LOAD_DASHBOARD_DATA,
  }),
  resetDashboardData: () => ({
    type: actions.RESET_DASHBOARD_DATA,
  }),
  getWinners: () => ({
    type: actions.GET_WINNERS,
  }),
  startRaffle: () => ({
    type: actions.START_RAFFLE,
  }),
  reqRaffleEntry: (payload) => ({
    type: actions.SEND_RAFFLE_ENTRY,
    payload,
  }),
  resetRaffleEntry: () => ({
    type: actions.RAFFLE_ENTRY,
    payload: null,
    success: null,
  }),
  resetWinner: () => ({
    type: actions.DONE_RAFFLE,
    payload: null,
  }),
};
export default actions;
