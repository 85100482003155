import actions from './actions';
import authActions from '../auth/actions';
// import dummyInventory from './dummyData'

const initState = {
  errorMessage: null,
  message: null,
  loadingBalances: false,
  loadingLimit: false,
  walletDetails: null,
  limitDetails: null,
  loadingBankAccounts: false,
  bankAccounts: null,
  isRequestOtp: false,
  otpMessage: null,
  isErrorOtp: false,
  isVerifyOtp: false,
  verifyOtpMessage: null,
  isErrorVerifyOtp: false,
  resendSuccessMessage: null,
  isResendOtp: false,
  loadingSupportedBanks: false,
  supportedBanks: null,
  loadingSendingOtp: false,
  otpSent: false,
  loadingSavingBank: false,
  savingBankResult: null,
  loadingResendingOtp: false,
  resendOtpMessage: null,
  loadingUpdatingBank: false,
  updatedBankResult: false,
  updateSuccess: false,
  loadingRemovingBank: false,
  removedBankResult: false,
  loadingChangingFavorite: false,
  removeSuccess: false,
  changingFailed: false,
  //
  transactionType: 'withdraw',
  //
  withdrawTransactionData: null,
  withdrawTransactionPagination: null,
  withdrawTransactionLoadmore: false,
  fetchingWithdrawTransaction: false,
  isErrorWithrawalRequest: false,
  //
  splitTransactionData: null,
  splitTransactionPagination: null,
  splitTransactionLoadmore: false,
  fetchingSplitTransaction: false,
  isErrorSplitTransactionRequest: false,
  //
  topupTransactionData: null,
  topupTransactionPagination: null,
  topupTransactionLoadmore: false,
  fetchingTopupTransaction: false,
  isErrorTopupRequest: false,
  //
  onlinePaymentTransactionData: null,
  onlinePaymentTransactionPagination: null,
  onlinePaymentTransactionLoadmore: false,
  fetchingOnlinePaymentTransaction: false,
  isErrorOnlinePaymentRequest: false,
  //
  fundTransferReceiveData: null,
  fundTransferReceivePagination: null,
  fundTransferReceiveLoadmore: false,
  fetchingfundTransferReceive: false,
  isErrorfundTransferReceive: false,
  //
  fundTransferSentData: null,
  fundTransferSentPagination: null,
  fundTransferSentLoadmore: false,
  fetchingfundTransferSent: false,
  isErrorfundTransferSent: false,
  //
  isGetWithdrawal: false,
  emailNotif: null,
  downloadingWithdrawal: false,
  downloadedWithdrawalData: null,
  downloadWithdrawalMessage: null,
  // downloadingSplit: false,
  // downloadedSplitData: null,
  // downloadSplitMessage: null,
  downloadingTopup: false,
  downloadedTopupData: null,
  downloadTopupMessage: null,
  withdrawalRequestData: null,
  updateStatusBoolean: false,
  updatedData: null,
  changingStatusFailed: false,
  changingStatusSuccess: false,
  loadingChangingStatus: false,
  // loadingSendingAutosweepOtp: false,
  // autosweepOtpSent: false,
  // autosweepOtpMessage: null,
  // savingAutoSweep: false,
  // savingAutoSweepResult: null,
  // savingAutoSweepMessage: null,
  walletTopUpResponse: null,
  walletTopUpErrorMessage: null,
  fundTransferLoading: false,
  fundTransferSuccess: false,
  fundTransferFailedMessage: null,
  fundTransferErrorMessage: null,
  modules: [
    {
      id: 'wallet',
      label: 'Biz.financials.menu.wallet',
      view: 'BizWallet'
    },
    {
      id: 'bankAccounts',
      label: 'Biz.financials.menu.banks',
      view: 'EnrolledBankAccounts'
    },
    // {
    //   id: 'card',
    //   label: 'Biz.financials.menu.card',
    //   view: 'Card'
    // },
    // {
    //   id: 'bookeeping',
    //   label: 'Biz.financials.menu.bookeeping',
    //   view: 'Bookeeping'
    // },
    // {
    //   id: 'reports',
    //   label: 'Biz.financials.menu.reports',
    //   view: 'TransactionReports'
    // },
  ],
};

export default (state = initState, action) => {

  switch (action.type) {
    case actions.SET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionType: action.payload,
      };
      //
    case actions.GET_WITHDRAWAL_TRANSACTION_SUCCESS:
      return {
        ...state,
        withdrawTransactionData: action.payload,
        withdrawTransactionPagination: action.pagination,
        withdrawTransactionLoadmore: false,
        fetchingWithdrawTransaction: false,
      };
    case actions.GETTING_WITHDRAWAL_TRANSACTION:
      return {
        ...state,
        fetchingWithdrawTransaction: true,
      };
    case actions.LOADMORE_WITHDRAWAL_TRANSACTION:
      return {
        ...state,
        withdrawTransactionLoadmore: true,
      };
    case actions.GET_WITHDRAWAL_TRANSACTION_FAILED:
      return {
        ...state,
        fetchingWithdrawTransaction: false,
        withdrawTransactionLoadmore: false,
      };
    case actions.GET_WITHDRAWAL_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorWithrawalRequest: action.payload,
        withdrawTransactionLoadmore: false,
      };
      //
    case actions.GET_SPLIT_TRANSACTION_SUCCESS:
      return {
        ...state,
        splitTransactionData: action.payload,
        splitTransactionPagination: action.pagination,
        splitTransactionLoadmore: false,
        fetchingSplitTransaction: false,
      };
    case actions.GETTING_SPLIT_TRANSACTION:
      return {
        ...state,
        fetchingSplitTransaction: true,
      };
    case actions.LOADMORE_SPLIT_TRANSACTION:
      return {
        ...state,
        splitTransactionLoadmore: true,
      };
    case actions.GET_SPLIT_TRANSACTION_FAILED:
      return {
        ...state,
        fetchingSplitTransaction: false,
        splitTransactionLoadmore: false,
      };
    case actions.GET_SPLIT_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorSplitTransactionRequest: action.payload,
        splitTransactionLoadmore: false,
      };
      //
    case actions.GET_TOPUP_TRANSACTION_SUCCESS:
      return {
        ...state,
        topupTransactionData: action.payload,
        topupTransactionPagination: action.pagination,
        topupTransactionLoadmore: false,
        fetchingTopupTransaction: false,
      };
    case actions.GETTING_TOPUP_TRANSACTION:
      return {
        ...state,
        fetchingTopupTransaction: true,
      };
    case actions.LOADMORE_TOPUP_TRANSACTION:
      return {
        ...state,
        topupTransactionLoadmore: true,
      };
    case actions.GET_TOPUP_TRANSACTION_FAILED:
      return {
        ...state,
        fetchingTopupTransaction: false,
        topupTransactionLoadmore: false,
      };
    case actions.GET_TOPUP_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorTopupRequest: action.payload,
        topupTransactionLoadmore: false,
      };
      //
    case actions.GET_ONLINE_PAYMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        onlinePaymentTransactionData: action.payload,
        onlinePaymentTransactionPagination: action.pagination,
        onlinePaymentTransactionLoadmore: false,
        fetchingOnlinePaymentTransaction: false,
      };
    case actions.GETTING_ONLINE_PAYMENT_TRANSACTION:
      return {
        ...state,
        fetchingOnlinePaymentTransaction: true,
      };
    case actions.LOADMORE_ONLINE_PAYMENT_TRANSACTION:
      return {
        ...state,
        onlinePaymentTransactionLoadmore: true,
      };
    case actions.GET_ONLINE_PAYMENT_TRANSACTION_FAILED:
      return {
        ...state,
        fetchingOnlinePaymentTransaction: false,
        onlinePaymentTransactionLoadmore: false,
      };
    case actions.GET_ONLINE_PAYMENT_TRANSACTION_ERROR:
      return {
        ...state,
        isErrorOnlinePaymentRequest: action.payload,
        onlinePaymentTransactionLoadmore: false,
      };
      //
      case actions.GET_FUND_TRANSFER_RECEIVE_SUCCESS:
      return {
        ...state,
        fundTransferReceiveData: action.payload,
        fundTransferReceivePagination: action.pagination,
        fundTransferReceiveLoadmore: false,
        fetchingfundTransferReceive: false,
      };
    case actions.GETTING_FUND_TRANSFER_RECEIVE:
      return {
        ...state,
        fetchingfundTransferReceive: true,
      };
    case actions.LOADMORE_FUND_TRANSFER_RECEIVE:
      return {
        ...state,
        fundTransferReceiveLoadmore: true,
      };
    case actions.GET_FUND_TRANSFER_RECEIVE_FAILED:
      return {
        ...state,
        fetchingfundTransferReceive: false,
        fundTransferReceiveLoadmore: false,
      };
    case actions.GET_FUND_TRANSFER_RECEIVE_ERROR:
      return {
        ...state,
        isErrorfundTransferReceive: action.payload,
        fundTransferReceiveLoadmore: false,
      };
      //
      case actions.GET_FUND_TRANSFER_SENT_SUCCESS:
      return {
        ...state,
        fundTransferSentData: action.payload,
        fundTransferSentPagination: action.pagination,
        fundTransferSentLoadmore: false,
        fetchingfundTransferSent: false,
      };
    case actions.GETTING_FUND_TRANSFER_SENT:
      return {
        ...state,
        fetchingfundTransferSent: true,
      };
    case actions.LOADMORE_FUND_TRANSFER_SENT:
      return {
        ...state,
        fundTransferSentLoadmore: true,
      };
    case actions.GET_FUND_TRANSFER_SENT_FAILED:
      return {
        ...state,
        fetchingfundTransferSent: false,
        fundTransferSentLoadmore: false,
      };
    case actions.GET_FUND_TRANSFER_SENT_ERROR:
      return {
        ...state,
        isErrorfundTransferSent: action.payload,
        fundTransferSentLoadmore: false,
      };
      //
    case actions.LOADING_BALANCES:
      return {
        ...state,
        loadingBalances: true,
      };
    case actions.LOADING_LIMIT:
      return {
        ...state,
        loadingLimit: true,
      };
    case actions.BALANCES_LOADED:
      return {
        ...state,
        loadingBalances: false,
        walletDetails: action.walletDetails,
      };
    case actions.LIMIT_LOADED:
      return {
        ...state,
        loadingLimit: false,
        limitDetails: action.limitDetails,
      };
    case actions.LOADING_BANK_ACCOUNTS:
      return {
        ...state,
        loadingBankAccounts: true,
      };
    case actions.BANK_ACCOUNTS_LOADED:
      return {
        ...state,
        loadingBankAccounts: false,
        bankAccounts: action.bankAccounts,
      };
    case actions.LOADING_BANK_ACCOUNTS_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        bankAccounts: null,
      };
    case actions.LOADING_LIMIT_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        limitDetails: null,
      };
    case actions.LOADING_BALANCES_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        walletDetails: null,
      };
    case actions.CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        errorMessage: null,
      };
    case actions.REQUESTING_OTP:
      return {
        ...state,
        isRequestOtp: action.payload,
      };
    case actions.REQUEST_OTP_FAILED:
      return {
        ...state,
        isRequestOtp: false,
      };
    case actions.REQUESTED_OTP:
      return {
        ...state,
        otpMessage: action.payload,
        isErrorOtp: action.isErrorOtp,
      };
    // VERIFY OTP
    case actions.VERIFIED_OTP:
      return {
        ...state,
        verifyOtpMessage: action.payload,
        isErrorVerifyOtp: action.isErrorVerifyOtp,
      };
    case actions.RESET_VERIFIED_OTP:
      return {
        ...state,
        verifyOtpMessage: null,
        isErrorVerifyOtp: false,
      };
    case actions.VERIFYING_OTP:
      return {
        ...state,
        isVerifyOtp: action.payload,
      };
    case actions.VERIFY_OTP_FAILED:
      return {
        ...state,
        isVerifyOtp: false,
      };
    case actions.LOADING_SUPPORTED_BANKS:
      return {
        ...state,
        loadingSupportedBanks: true,
      };
    case actions.SUPPORTED_BANK_LOADED:
      return {
        ...state,
        loadingSupportedBanks: false,
        supportedBanks: action.supportedBanks,
      };
    case actions.LOADING_SUPPORTED_BANKS_FAILED:
      return {
        ...state,
        loadingLimit: false,
        loadingBankAccounts: false,
        loadingBalances: false,
        loadingSupportedBanks: false,
        supportedBanks: null,
      };
    case actions.SENDING_SMS_OTP:
      return {
        ...state,
        loadingSendingOtp: true,
        otpSent: false,
      };
    case actions.SMS_OTP_SENT:
      return {
        ...state,
        loadingSendingOtp: false,
        otpSent: true,
        otpMessage: 'OTP Sent',
      };
    case actions.SENDING_SMS_OTP_FAILED:
      return {
        ...state,
        loadingSendingOtp: false,
        otpSent: false,
        otpMessage: 'NO OTP RECIPIENT',
      };
    // case actions.RESET_OTP:
    //   return {
    //     ...state,
    //     loadingSendingOtp: false,
    //     otpSent: false,
    //     otpMessage: null,
    //   };
    case actions.SAVING_BANK_ACCOUNT:
      return {
        ...state,
        loadingSavingBank: true,
      };
    case actions.BANK_ACCOUNT_SAVED:
      return {
        ...state,
        loadingSavingBank: false,
        savingBankResult: action.result,
        bankAccounts: action.bankAccounts,
      };
    case actions.SAVING_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loadingSavingBank: false,
        savingBankResult: null,
      };
    // case actions.RESET_SAVING_NOTIF:
    //   return {
    //     ...state,
    //     loadingSavingBank: false,
    //     savingBankResult: null,
    //   };
    case actions.RESENDING_OTP:
      return {
        ...state,
        isResendOtp: action.payload,
      };
    case actions.RESEND_OTP_SENT:
      return {
        ...state,
        loadingResendingOtp: false,
        resendOtpMessage: action.message,
      };
    case actions.RESENDING_OTP_FAILED:
      return {
        ...state,
        loadingResendingOtp: false,
        resendOtpMessage: action.message,
      };
    case actions.W_RESET_RESEND_OTP:
      return {
        ...state,
        resendSuccessMessage: null,
        isErrorResendOtp: false,
      };
    case actions.W_RESENDING_OTP:
      return {
        ...state,
        isResendOtp: action.payload,
      };
    case actions.W_RESEND_OTP_SUCCESS:
      return {
        ...state,
        resendSuccessMessage: action.payload,
        isErrorResendOtp: action.isErrorResendOtp,
      };
    case actions.W_RESEND_OTP_FAILED:
      return {
        ...state,
        isResendOtp: false,
        loadingResendingOtp: true,
      };
    case actions.UPDATING_BANK_ACCOUNT:
      return {
        ...state,
        loadingUpdatingBank: true,
      };
    case actions.BANK_ACCOUNT_UPDATED:
      return {
        ...state,
        loadingUpdatingBank: false,
        updatedBankResult: true,
        bankAccounts: action.bankAccounts,
        updateSuccess: true,
      };
    case actions.UPDATING_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loadingUpdatingBank: false,
        updatedBankResult: true,
        updateSuccess: false,
      };
    case actions.RESET_MESSAGE_NOTIF:
      return {
        ...state,
        loadingResendingOtp: false,
        resendOtpMessage: null,
        loadingSavingBank: false,
        savingBankResult: null,
        loadingSendingOtp: false,
        otpSent: false,
        otpMessage: null,
        loadingUpdatingBank: false,
        updatedBankResult: false,
        updateSuccess: false,
        loadingRemovingBank: false,
        removedBankResult: false,
        removeSuccess: false,
        changingFailed: false,
        loadingChangingFavorite: false,
        changingStatusFailed: false,
        changingStatusSuccess: false,
        loadingChangingStatus: false,
      };
    case actions.REMOVING_BANK_ACCOUNT:
      return {
        ...state,
        loadingRemovingBank: true,
      };
    case actions.BANK_ACCOUNT_REMOVED:
      return {
        ...state,
        loadingRemovingBank: false,
        removedBankResult: true,
        bankAccounts: action.bankAccounts,
        removeSuccess: true,
      };
    case actions.REMOVING_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loadingRemovingBank: false,
        removedBankResult: true,
        removeSuccess: false,
      };
    case actions.CHANGING_FAVORITE:
      return {
        ...state,
        loadingChangingFavorite: true,
      };
    case actions.FAVORITE_CHANGED:
      return {
        ...state,
        loadingChangingFavorite: false,
        bankAccounts: action.bankAccounts,
      };
    case actions.CHANGING_FAVORITE_FAILED:
      return {
        ...state,
        loadingChangingFavorite: false,
        changingFailed: true,
      };
    case actions.CHANGING_STATUS:
      return {
        ...state,
        loadingChangingStatus: true,
      };
    case actions.STATUS_CHANGED:
      return {
        ...state,
        bankAccounts: action.bankAccounts,
        loadingChangingStatus: false,
        changingStatusSuccess: true,
      };
    case actions.CHANGING_STATUS_FAILED:
      return {
        ...state,
        changingStatusFailed: true,
        loadingChangingStatus: false,
      };
    case actions.EMAIL_NOTIF:
      return {
        ...state,
        emailNotif: action.payload,
        changingFailed: true,
      };
    case actions.DOWNLOAD_WITHDRAWAL_LOADING:
      return {
        ...state,
        downloadingWithdrawal: true,
      };
    case actions.DOWNLOAD_WITHDRAWAL_LOADED:
      return {
        ...state,
        downloadedWithdrawalData: action.payload,
        downloadingWithdrawal: false,
      };
    case actions.DOWNLOAD_WITHDRAWAL_MESSAGE:
      return {
        ...state,
        downloadWithdrawalMessage: action.payload,
        downloadedWithdrawalData: null,
        downloadingWithdrawal: false,
      };
    // case actions.DOWNLOAD_SPLIT_LOADING:
    //   return {
    //     ...state,
    //     downloadingSplit: true,
    //   };
    // case actions.DOWNLOAD_SPLIT_LOADED:
    //   return {
    //     ...state,
    //     downloadedSplitData: action.payload,
    //     downloadingSplit: false,
    //   };
    // case actions.DOWNLOAD_SPLIT_MESSAGE:
    //   return {
    //     ...state,
    //     downloadSplitMessage: action.payload,
    //     downloadedSplitData: null,
    //     downloadingSplit: false,
    //   };
    case actions.DOWNLOAD_TOPUP_LOADING:
      return {
        ...state,
        downloadingTopup: true,
      };
    case actions.DOWNLOAD_TOPUP_LOADED:
      return {
        ...state,
        downloadedTopupData: action.payload,
        downloadingTopup: false,
      };
    case actions.DOWNLOAD_TOPUP_MESSAGE:
      return {
        ...state,
        downloadTopupMessage: action.payload,
        downloadedTopupData: null,
        downloadingTopup: false,
      };
    case actions.FETCH_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawalRequestData: action.payload,
      };
    case actions.FETCH_WITHDRAWAL_REQUEST_FAILED:
      return {
        ...state,
        withdrawalRequestData: action.payload,
      };
    case actions.UPDATING_WITHDRAWAL_STATUS:
      return {
        ...state,
        updateStatusBoolean: action.payload,
      };
    case actions.UPDATED_WITHDRAWAL_STATUS:
      return {
        ...state,
        updateData: action.payload,
      };
    case actions.UPDATING_FAILED_WITHDRAWAL_STATUS:
      return {
        ...state,
        updateStatusBoolean: false,
      };
    // case actions.SENDING_AUTOSWEEP_OTP:
    //   return {
    //     ...state,
    //     loadingSendingAutosweepOtp: true,
    //     autosweepOtpSent: false,
    //   };
    // case actions.AUTOSWEEP_OTP_SENT:
    //   return {
    //     ...state,
    //     loadingSendingAutosweepOtp: false,
    //     autosweepOtpSent: action.otpSent,
    //     autosweepOtpMessage: action.otpMessage,
    //   };
    // case actions.SENDING_AUTOSWEEP_OTP_FAILED:
    //   return {
    //     ...state,
    //     loadingSendingAutosweepOtp: false,
    //     autosweepOtpSent: action.otpSent,
    //     autosweepOtpMessage: action.otpMessage,
    //   };
    // case actions.SAVING_AUTO_SWEEP:
    //   return {
    //     ...state,
    //     savingAutoSweep: true,
    //   };
    // case actions.SAVED_AUTO_SWEEP:
    //   const newAutoSweep = action.result;
    //   delete newAutoSweep.success;
    //   const bankList = state.bankAccounts;
    //   for (let i = 0; i < bankList.length; i++) {
    //     const bank = bankList[i];
    //     if (newAutoSweep.bankAccountNo === bank.bankAccountNo) {
    //       bankList[i].autosweepInfo = newAutoSweep;
    //     }
    //   }
    //   return {
    //     ...state,
    //     savingAutoSweep: false,
    //     savingAutoSweepResult: action.result,
    //     savingAutoSweepMessage: null,
    //     bankInfo: bankList,
    //   };
    // case actions.SAVING_AUTO_SWEEP_FAILED:
    //   return {
    //     ...state,
    //     savingAutoSweep: false,
    //     savingAutoSweepResult: null,
    //     savingAutoSweepMessage: action.message,
    //   };
    case actions.WALLET_TOP_UP_SUCCESS:
      return {
        ...state,
        walletTopUpResponse: action.result,
      };
    case actions.WALLET_TOP_UP_FAILED:
      return {
        ...state,
        walletTopUpErrorMessage: action.message,
      };
    case actions.WALLET_TOP_UP_RESET:
      return {
        ...state,
        walletTopUpResponse: null,
        walletTopUpErrorMessage: null,
      };
    case actions.FUND_TRANSFER_LOADING:
      return {
        ...state,
        fundTransferLoading: true,
        fundTransferFailedMessage: null,
        fundTransferSuccess: false,
        fundTransferErrorMessage: null,
      };
    case actions.FUND_TRANSFER_SUCCESS:
      return {
        ...state,
        fundTransferLoading: false,
        fundTransferFailedMessage: null,
        fundTransferSuccess: true,
      };
    case actions.FUND_TRANSFER_FAILED:
      return {
        ...state,
        fundTransferFailedMessage: action.message,
        fundTransferLoading: false,
      };
    case actions.FUND_TRANSFER_ERROR:
      return {
        ...state,
        fundTransferErrorMessage: action.message,
        fundTransferLoading: false,
      };
    case actions.FUND_TRANSFER_RESET:
      return {
        ...state,
        fundTransferErrorMessage: null,
        fundTransferFailedMessage: null,
        fundTransferLoading: false,
      };
    case authActions.LOGOUT:
      // reset all redux param values
      return initState;
    default:
      return state;
  }
}