import { all, put, takeEvery, fork, select } from 'redux-saga/effects';
import actions from './actions';
import { get, post } from '@iso/lib/helpers/resApiRequestor';
// import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';


export function* fetchAuthManagementRole() {
  yield takeEvery('FETCH_AUTH_MANAGEMENT_ROLE', function* () {
    try {
      const apiResult = yield fetchAuthManagementRoleRequest();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_AUTH_MANAGEMENT_ROLE,
      });
      if (result.success === true) {
        yield put({
          type: actions.AUTH_MANAGEMENT_ROLE_FETCHED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_AUTH_MANAGEMENT_ROLE_FAILED,
        payload: error,
      });
    }
  });
}
// 
export function* fetchRolePermission() {
  yield takeEvery('FETCH_ROLE_PERMISSION', function* (payload) {
    try {
      const apiResult = yield fetchRolePermissionRequest(payload.payload);
      const result = apiResult.data;
      const apiResultCore = yield fetchCoreModuleRequest(payload.payload);
      const resultCore = apiResultCore.data;
      yield put({
        type: actions.FETCHING_ROLE_PERMISSION,
      });
      if (result.success === true) {
        yield put({
          type: actions.ROLE_PERMISSION_FETCHED,
          payload: result.data,
          payloadCore: resultCore.data,
          selectedId: payload.payload,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ROLE_PERMISSION_FAILED,
        payload: error,
      });
    }
  });
}

export function* saveAuthManagement() {
  yield takeEvery('SAVE_AUTH_MANAGEMENT', function* (payload) {
    const data = payload.payload;
    try {
      const apiResult = yield saveAuthManagementReq(data.selectedId, data);
      const result = apiResult.data;
      yield put({
        type: actions.SAVING_AUTH_MANAGEMENT,
      });
      if (result.success === true) {
        yield put({
          type: actions.AUTH_MANAGEMENT_SAVED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SAVE_AUTH_MANAGEMENT_FAILED,
        payload: error,
      });
    }
  });
}
export function* addAuthRole() {
  yield takeEvery('ADD_AUTH_ROLE', function* (payload) {
    try {
      const apiResult = yield addAuthRoleReq(payload.payload);
      const result = apiResult.data;
      const apiResultRole = yield fetchAuthManagementRoleRequest();
      const resultRole = apiResultRole.data;
      yield put({
        type: actions.ADDING_AUTH_ROLE,
      });
      if (result.success === true) {
        yield put({
          type: actions.AUTH_ROLE_ADDED,
          payload: result.data,
        });
        yield put({
          type: actions.FETCHING_AUTH_MANAGEMENT_ROLE,
        });
      }
      if (resultRole.success === true) {
        yield put({
          type: actions.AUTH_MANAGEMENT_ROLE_FETCHED,
          payload: resultRole.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_AUTH_ROLE_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchStatus() {
  yield takeEvery('FETCH_STATUS', function* () {
    try {
      const apiResult = yield fetchStatusRequest();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_STATUS,
      });
      if (result.success === true) {
        yield put({
          type: actions.STATUS_FETCHED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_STATUS_FAILED,
        payload: error,
      });
    }
  });
}

////

export function* fetchMerchantUsers() {
  yield takeEvery('FETCH_SETTINGS_MERCHANT_USERS', function* (payload) {
    const state = yield select();
    const merchantIdentity = state.Auth.identity;
    const store = state.Auth.stores;
    const loadmore = payload.payload.loadmore;
    if (loadmore) {
      yield put({
        type: actions.LOADING_MORE_SETTINGS_MERCHANT_USERS,
      });
    } else {
      yield put({
        type: actions.FETCHING_SETTINGS_MERCHANT_USERS,
      });
    }
    try {
      const apiResult = yield fetchMerchantUsersRequest(merchantIdentity, store, payload.payload);
      const result = apiResult.data;
      const currentList = payload.payload.currentUserList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      yield put({
        type: actions.SETTINGS_MERCHANT_USERS_FETCHED,
        merchantUsersData: finalData,
        pagination: result.data.pagination
      });
      if (!loadmore) {
        yield put({
          type: actions.SET_SETTINGS_MERCHANT_USER_DATA,
          payload: result.data.result[0],
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_SETTINGS_MERCHANT_USERS_FAILED,
      });
    }
  });
}
/////





// AUTH MANAGEMENT
function fetchAuthManagementRoleRequest() {
  return get(`roles/fetch_all_roles/ui`);
}

//
function fetchCoreModuleRequest() {
  // return get(`core-modules/system/modules/new/${id}`);
  return get(`core-modules/system/modules/new`);
}
function fetchRolePermissionRequest(id) { // here
  return get(`roles/role_authorization/${id}`);
}
function saveAuthManagementReq(id, data) {
  // return get(`core-modules/system/modules/new/${id}`);
  return post(`roles/${id}/authorization/update_roles_auth`, data);
}
function addAuthRoleReq(data) {
  return post(`lookups/save_role`, data);
}
function fetchStatusRequest() {
  // add endpoint for auth management save
  return get(`lookups/fetch/status/`);
}
function fetchMerchantUsersRequest(merchantIdentity, store, payload) {
  const newData = {
    mid: payload && payload.mid ? payload.mid : merchantIdentity.merchantId,
    role: merchantIdentity.role.role.label,
    store: store.length > 0 ? store[0].storeId : '',
    authId: merchantIdentity.Id,
  };
  // return post(`users/get_all_users/new/`, newData);
  return get(`users/get_all/new/`, newData, payload.page, null, null);
}

export default function* rootSaga() {
  yield all([
    fork(fetchAuthManagementRole),
    fork(saveAuthManagement),
    fork(fetchStatus),
    fork(addAuthRole),
    fork(fetchRolePermission),
  ]);
}
