import { all, put, takeEvery, fork, select } from 'redux-saga/effects';
import actions from './actions';
import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';

export function* loadMerchantInfo() {
  yield takeEvery('LOAD_MERCHANT_INFO', function* () {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.LOADING_MERCHANT_INFO,
      });
      const apiResult = yield fetchMerchantByMid(merchantId);
      // const result = apiResult.data;
      const imgResult = yield fetchImageRequest(apiResult.data.data.fileName);
      const withdrawalAccounts = yield fetchWithdrawalAccounts(merchantId);
      const finalBase64 = `data:${apiResult.data.data.fileType};base64,${imgResult.data.base64}`;
      const merchantInfo = yield fetchMerchantById(apiResult.data.data.Id);
      yield put({
        type: actions.MERCHANT_INFO_LOADED,
        merchantInfo: merchantInfo.data.data,
        merchantLogo: finalBase64,
        bankAccount: withdrawalAccounts.data.data,
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_MERCHANT_INFO_FAILED,
      });
    }
  });
}

export function* fetchMerchantImage() {
  yield takeEvery('FETCH_MERCHANT_IMAGE', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_IMAGE,
      });
      const checkVerifiedStore = yield checkVerified(payload.payload);
      if (checkVerifiedStore.status === 200 && checkVerifiedStore.data.store === 'VERIFIED') {
        const storeImg = yield fetchStoreImg(payload.payload);
        if (storeImg.status === 200) {
          const imgResult = yield fetchImageRequest(storeImg.data.data.fileName);
          const finalBase64 = imgResult.data.base64 !== 'NO IMAGE FOUND' ? `data:${storeImg.data.data.fileType};base64,${imgResult.data.base64}` : imgResult.data.base64;
          const storeChannels = yield fetchStoreChannels(payload.payload);
          const storeInfo = yield fetchStoreInformation(payload.payload);
          if (storeChannels.status === 200 && storeInfo.data.success === true) {
            yield put({
              type: actions.FETCHED_MERCHANT_IMAGE,
              storeChannels: storeChannels.data.data,
              merchantLogo: finalBase64,
              grabToken: storeImg.data.data.grabToken,
              storeInfo: storeInfo.data.data,
            });
          } else if (storeInfo.data.success === false){
            yield put({
              type: actions.FETCH_MERCHANT_IMAGE_FAILED,
              payload: 'Store not found.',
            });
          } else {
            yield put({
              type: actions.FETCHED_MERCHANT_IMAGE,
              storeChannels: [],
              merchantLogo: finalBase64,
              grabToken: storeImg.data.data.grabToken,
              storeInfo: storeInfo.data.data,
            });
          }
        } else {
          const storeChannels = yield fetchStoreChannels(payload.payload);
          const storeInfo = yield fetchStoreInformation(payload.payload);
          if (storeChannels.status === 200 && storeInfo.data.success === true) {
            yield put({
              type: actions.FETCHED_MERCHANT_IMAGE,
              storeChannels: storeChannels.data.data,
              merchantLogo: null,
              grabToken: null,
              storeInfo: storeInfo.data.data,
            });
          } else if (storeInfo.data.success === false){
            yield put({
              type: actions.FETCH_MERCHANT_IMAGE_FAILED,
              payload: 'Store not found.',
            });
          } else {
            yield put({
              type: actions.FETCHED_MERCHANT_IMAGE,
              storeChannels: null,
              merchantLogo: null,
              grabToken: null,
              storeInfo: storeInfo.data.data,
            });
          }
        }
      } else if (checkVerifiedStore.status === 200 && checkVerifiedStore.data.store === 'NOT VERIFIED') {
        yield put({
          type: actions.FETCH_MERCHANT_IMAGE_FAILED,
          // payload: checkVerifiedStore.data.message,
          payload: 'Payment via scan QR is not yet activated for this merchant. KYC approval is required.',
        });
      } else {
        yield put({
          type: actions.FETCH_MERCHANT_IMAGE_FAILED,
          payload: 'Store not found.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANT_IMAGE_FAILED,
        payload: 'Store not found.',
      });
    }
  });
}

export function* fetchCustomerData() {
  yield takeEvery('FETCH_CUSTOMER_DATA', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_CUSTOMER_DATA,
      });
      const customerData = yield getCustomerData(payload.payload);
      if (customerData.status === 200) {
        yield put({
          type: actions.FETCHED_CUSTOMER_DATA,
          payload: customerData.data.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_CUSTOMER_DATA_FAILED,
      });
    }
  });
}

export function* paymentTransactionRequest() {
  yield takeEvery('PAYMENT_TRANSACTION_REQUEST', function* (payload) {
    try {
      yield put({
        type: actions.PAYMENT_TRANSACTION_REQUESTING,
      });
      const payTrxRequest = yield payTransactionRequest(payload.payload);
      const res = payTrxRequest.data;
      if (payTrxRequest.status === 200) {
        if (res.response_code === 'GR033') {
          // return dispatch(successItem(res));
          yield put({
            type: actions.PAYMENT_TRANSACTION_REQUESTED,
            payload: res,
          });
        } else {
          if (res.response_message) {
            yield put({
              type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
              payload: res.response_message,
            });
          } else {
            yield put({
              type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
              payload: 'Error on creating transaction.',
            });
          }
        }
      } else {
        if (res.message && !res.success) {
          yield put({
            type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
            payload: res.message,
          });
        } else {
        yield put({
          type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
          payload: 'Error on creating transaction.',
        });
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        yield put({
          type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
          payload: error.response.data.message,
        });
      } else {
        yield put({
          type: actions.PAYMENT_TRANSACTION_REQUEST_FAILED,
          payload: 'Error on creating transaction.',
        });
      }
    }
  });
}

export function* loadPchannels() {
  yield takeEvery('LOAD_MERCHANT_PCHANNEL', function* () {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      const apiResult = yield fetchPchannels(merchantId);
      const result = apiResult.data;
      yield put({
        type: actions.LOADING_MERCHANT_PCHANNEL,
      });
      yield put({
        type: actions.MERCHANT_PCHANNEL_LOADED,
        merchantPchannel: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_MERCHANT_PCHANNEL_FAILED,
      });
    }
  });
}
export function* fetchMerchantUsers() {
  yield takeEvery('FETCH_MERCHANT_USERS', function* (payload) {
    const state = yield select();
    const merchantIdentity = state.Auth.identity;
    const store = state.Auth.stores;
    const loadmore = payload.payload.loadmore;
    const merchantList = state.Auth.merchantList;
    const newMids = merchantList ? merchantList.map(v => v.merchantId) : [];
    if (loadmore) {
      yield put({
        type: actions.LOADING_MORE_MERCHANT_USERS,
      });
    } else {
      yield put({
        type: actions.FETCHING_MERCHANT_USERS,
      });
    }
    try {
      const apiResult = yield fetchMerchantUsersRequestWhiteLabel(merchantIdentity, store, payload.payload, newMids);
      const result = apiResult.data;
      const currentList = payload.payload.currentUserList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      yield put({
        type: actions.MERCHANT_USERS_FETCHED,
        merchantUsersData: finalData,
        pagination: result.data.pagination
      });
      if (!loadmore) {
        yield put({
          type: actions.SET_MERCHANT_USER_DATA,
          payload: result.data.result[0],
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANT_USERS_FAILED,
      });
    }
  });
}
export function* updateMerchantUser() {
  yield takeEvery('UPDATE_MERCHANT_USER', function* (payload) {
    const state = yield select();
    const merchantIdentity = state.Auth.identity;
    try {//
      const apiResult = yield updateMerchantUserRequest(payload.payload, merchantIdentity);
      const result = apiResult.data;
      yield put({
        type: actions.UPDATING_MERCHANT_USER,
      });
      if (result.success === true) {
        yield put({
          type: actions.MERCHANT_USER_UPDATED,
          merchantUserUpdatedData: result.data,
        });
        yield put({
          type: actions.UPDATE_MERCHANT_USER_SUCCESS,
        });
      } else {
        yield put({
          type: actions.UPDATE_MERCHANT_USER_FAILED,
          updateError: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_MERCHANT_USER_FAILED,
      });
    }
  });
}
export function* addMerchantUser() {
  yield takeEvery('ADD_MERCHANT_USER', function* (payload) {
    const state = yield select();
    const merchantIdentity = state.Auth.identity;
    try {
      const apiResult = yield addMerchantUserRequest(payload.payload, merchantIdentity);
      const result = apiResult.data;
      yield put({
        type: actions.ADDING_MERCHANT_USER,
      });
      if (result.success === true) {
        yield put({
          type: actions.MERCHANT_USER_ADDED,
        });
      } else {
        yield put({
          type: actions.ADD_MERCHANT_USER_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_MERCHANT_USER_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchAllFilteredRole() {
  yield takeEvery('FETCH_ALL_FILTERED_ROLE', function* () {
    try {
      const apiResult = yield fetchAllFilteredRoleRequest();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_ALL_FILTERED_ROLE,
      });
      if (result.success === true) {
        yield put({
          type: actions.FILTERED_ROLE_FETCHED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ALL_FILTERED_ROLE_FAILED,
        payload: error,
      });
    }
  });
}

export function* deactivateMerchantUser() {
  yield takeEvery('DEACTIVATE_MERCHANT_USER', function* (payload) {
    const state = yield select();
    const merchantIdentity = state.Auth.identity;
    // const merchantSelected = state.Auth.selectedMerchant;
    const store = state.Auth.stores;
    try {
      const apiResult = yield deactivateMerchantUserRequest(payload.payload.values, merchantIdentity);
      const result = apiResult.data;
      yield put({
        type: actions.DEACTIVATING_MERCHANT_USER,
      });
      if (result.success === true) {
        yield put({
          type: actions.MERCHANT_USER_DEACTIVATED,
        });
        yield put({
          type: actions.FETCHING_MERCHANT_USERS,
        });
        const apiResultAllUsers = yield fetchMerchantUsersRequest(merchantIdentity, store, payload.payload);
        const resultUsers = apiResultAllUsers.data;
        yield put({
          type: actions.MERCHANT_USERS_FETCHED,
          merchantUsersData: resultUsers.data.result,
          pagination: resultUsers.data.pagination
        });
        yield put({
          type: actions.SET_MERCHANT_USER_DATA,
          payload: resultUsers.data.result[0],
        });
      }
    } catch (error) {
      yield put({
        type: actions.DEACTIVATE_MERCHANT_USER_FAILED,
      });
    }
  });
}
export function* resetMerchantUserPassword() {
  yield takeEvery('RESET_MERCHANT_USER_PASSWORD', function* (payload) {
    try {
      const apiResult = yield resetMerchantUserPasswordRequest(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.RESETTING_MERCHANT_USER_PASSWORD,
      });
      if (result.success) {
        yield put({
          type: actions.MERCHANT_USER_PASSWORD_RESET_SUCCESS,
        });
      } else {
        yield put({
          type: actions.MERCHANT_USER_PASSWORD_RESET_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.MERCHANT_USER_PASSWORD_RESET_ERROR,
      });
    }
  });
}

export function* getAllPchannels() {
  yield takeEvery('GET_ALL_PCHANNELS', function* () {
    try {
      yield put({
        type: actions.GETTING_ALL_PCHANNELS,
      });
      const apiResult = yield fetchAllPchannels();
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.GET_ALL_PCHANNELS_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.GET_ALL_PCHANNELS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_ALL_PCHANNELS_FAILED,
      });
    }
  });
}

export function* editMerchantDetails() {
  yield takeEvery('EDIT_MERCHANT_DETAILS', function* (payload) {
    try {
      yield put({
        type: actions.EDITING_MERCHANT_DETAILS,
      });
      const apiResult = yield updateMerchantDetails(payload);
      const result = apiResult.data;
      if (result.success === true) {
        yield put({
          type: actions.MERCHANT_DETAILS_EDITED,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.EDIT_MERCHANT_DETAILS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_MERCHANT_DETAILS_FAILED,
      });
    }
  });
}

export function* downloadHistory() {
  yield takeEvery('DOWNLOAD_HISTORY', function* (payload) {
    try {
      yield put({
        type: actions.DOWNLOADING_HISTORY,
      });
      const apiResult = yield generateData(payload.payload);
      const result = apiResult.data;
      if (apiResult.status === 200) {
        if (result.count <= 0) {
          yield put({
            type: actions.DOWNLOAD_HISTORY_FAILED,
            message: 'No transaction found.'
          });
        }
      } else {
        yield put({
          type: actions.DOWNLOAD_HISTORY_FAILED,
          message: 'Failed to download history.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_HISTORY_FAILED,
        message: 'Failed to download history.'
      });
    }
  });
}

export function* downloadStoreTxn() {
  yield takeEvery('DOWNLOAD_STORE_TXN', function* (payload) {
    try {
      yield put({
        type: actions.DOWNLOADING_STORE_TXN,
        result: payload.payload.storeId,
      });
      const apiResult = yield generateStoreData(payload.payload);
      const result = apiResult.data;
      if (apiResult.status === 200) {
        if (result.count <= 0) {
          yield put({
            type: actions.DOWNLOAD_STORE_TXN_FAILED,
            message: 'No transaction found.'
          });
        }
      } else {
        yield put({
          type: actions.DOWNLOAD_STORE_TXN_FAILED,
          message: 'Failed to download transactions.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_STORE_TXN_FAILED,
        message: 'Failed to download transactions.'
      });
    }
  });
}
export function* fetchRiskProfile() {
  yield takeEvery('FETCH_RISK_PROFILE', function* () {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const selectedMerchant = state.Auth.selectedMerchant;
    try {
      yield put({
        type: actions.FETCHING_RISK_PROFILE,
      });
      let apiResult;
      if (role !== 'Administrator') {
        apiResult = yield fetchRiskProfileRequest();
      } else {
        apiResult = yield fetchRiskProfileRequestAdmin(selectedMerchant);
      }
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHING_RISK_PROFILE_SUCCESS,
          riskProfileData: result.data,
        });
      } else {
        yield put({
          type: actions.FETCHING_RISK_PROFILE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_RISK_PROFILE_FAILED,
      });
    }
  });
}

export function* getKycQualified() {
  yield takeEvery('GET_KYC_QUALIFIED', function* () {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator') {
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.GETTING_KYC_QUALIFIED,
      });
      const apiResult = yield fetchKycQualified(merchantId);
      if (apiResult.status === 200) {
        const kycRequirements = yield fetchKycRequirements();
        const result = apiResult.data;
        yield put({
          type: actions.GOT_KYC_QUALIFIED,
          data: result.data ? result.data : null,
          requirements: kycRequirements.status === 200 ? kycRequirements.data.data : [],
        });
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_KYC_QUALIFIED_FAILED,
      });
    }
  });
}

export function* submitKycDocs() {
  yield takeEvery('POST_KYC_DOCS', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_KYC_DOCS,
      });
      if (payload.payload.update) {
        yield updateKycDocs(payload.payload);
      } else {
        yield postKycDocs(payload.payload);
      }
      // const apiResult = yield postKycDocs(payload.payload);
      // if (apiResult.data.success) {
      //   const kyc = yield fetchKycQualified(payload.payload.merchantId);
      //   if (kyc.status === 200) {
      //     yield put({
      //       type: actions.POSTED_KYC_DOCS,
      //       data: kyc.data.data,
      //     });
      //   }
      // } else {
      //   yield put({
      //     type: actions.POSTING_KYC_DOCS_FAILED,
      //   });
      // }
    } catch (error) {
      yield put({
        type: actions.POSTING_KYC_DOCS_FAILED,
      });
    }
  });
}

export function* reloadKyc() {
  yield takeEvery('RELOAD_KYC', function* () {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    try {
      const kyc = yield fetchKycQualified(merchantId);
      if (kyc.status === 200) {
        yield put({
          type: actions.POSTED_KYC_DOCS,
          data: kyc.data.data,
        });
      } else {
        yield put({
          type: actions.POSTING_KYC_DOCS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.POSTING_KYC_DOCS_FAILED,
      });
    }
  });
}

export function* previewKycDocs() {
  yield takeEvery('PREVIEW_KYC_DOCS', function* (payload) {
    try {
      yield put({
        type: actions.PREVIEWING_KYC_DOCS,
      });
      const apiResult = yield getMerchantDocs(payload.payload);
      if (apiResult.data.success) {
        const result = apiResult.data;
        yield put({
          type: actions.PREVIEWED_KYC_DOCS,
          data: result ? result.base64 : null,
        });
      } else {
        yield put({
          type: actions.PREVIEWING_KYC_DOCS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.PREVIEWING_KYC_DOCS_FAILED,
      });
    }
  });
}
export function* fetchMerchantReferralLink() {
  yield takeEvery('FETCH_MERCHANT_REFERRAL_LINK', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_REFERRAL_LINK,
      });
      const apiResult = yield fetchMerchantReferralLinkRequest(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCH_MERCHANT_REFERRAL_LINK_SUCCESS,
          referralLinkdata: result.data,
        });
      } else {
      }
    } catch (error) {
    }
  });
}

export function* uploadLogo() {
  yield takeEvery('UPLOAD_MERCHANT_LOGO', function* (payload) {
    try {
      yield put({
        type: actions.UPLOADING_MERCHANT_LOGO,
      });
      const apiResult = yield uploadMerchLogo(payload.payload[0]);
      const result = apiResult.data;
      if (apiResult.data.success) {
        yield put({
          type: actions.UPLOADED_MERCHANT_LOGO,
          data: result.data,
          // message: 'Logo uploaded successfully.'
        });
      } else {
        yield put({
          type: actions.UPLOAD_MERCHANT_LOGO_FAILED,
          message: 'Logo upload failed.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPLOAD_MERCHANT_LOGO_FAILED,
        message: 'Logo upload failed.'
      });
    }
  });
}

export function* askForReupload() {
  yield takeEvery('ASK_FOR_REUPLOAD', function* (payload) {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator') {
      // merchantId = 'PAYNAMICS_12345'
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.ASKING_FOR_REUPLOAD,
      });
      const apiResult = yield requestReupload(merchantId, payload.payload);
      if (apiResult.status === 200) {
        const kyc = yield fetchKycQualified(merchantId);
        if (kyc.status === 200) {
          yield put({
            type: actions.ASKED_FOR_REUPLOAD,
            data: kyc.data.data,
          });
        } else {
          yield put({
            type: actions.ASKING_FOR_REUPLOAD_FAILED,
          });
        }
      } else {
        yield put({
          type: actions.ASKING_FOR_REUPLOAD_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ASKING_FOR_REUPLOAD_FAILED,
      });
    }
  });
}

export function* approveKycDocs() {
  yield takeEvery('APPROVE_KYC_DOCS', function* (payload) {
    const state = yield select();
    let merchantId = state.Auth.identity.merchantId;
    const accountRole = state.Auth.identity.role.role.label;
    if (accountRole === 'Administrator') {
      // merchantId = 'PAYNAMICS_12345'
      merchantId = state.Auth.selectedMerchant;
    }
    try {
      yield put({
        type: actions.APPROVING_KYC_DOCS,
      });
      const apiResult = yield requestApprove(merchantId);
      if (apiResult.data.success) {
        const kyc = yield fetchKycQualified(merchantId);
        if (kyc.status === 200) {
          yield put({
            type: actions.APPROVED_KYC_DOCS,
            data: kyc.data.data,
          });
        } else {
          yield put({
            type: actions.APPROVING_KYC_DOCS_FAILED,
          });
        }
      } else {
        yield put({
          type: actions.APPROVING_KYC_DOCS_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.APPROVING_KYC_DOCS_FAILED,
      });
    }
  });
}

export function* updateMerchantChannels() {
  yield takeEvery('UPDATE_MERCHANT_CHANNELS', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_MERCHANT_CHANNELS,
      });
      const apiResult = yield editMerchantChannels(payload);
      const result = apiResult.data;
      if (result.success === true) {
        yield put({
          type: actions.MERCHANT_CHANNELS_UPDATED,
          result: result.data,
        });
      } else {
        yield put({
          type: actions.UPDATE_MERCHANT_CHANNELS_FAILED,
          message: 'Logo upload failed.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_MERCHANT_CHANNELS_FAILED,
        message: 'Logo upload failed.'
      });
    }
  });
}

export function* newResetMerchantUserPassword() {
  yield takeEvery('NEW_RESET_MERCHANT_USER_PASSWORD', function* (payload) {
    try {
      const apiResult = yield newResetMerchantUserPasswordRequest(payload.payload.newData, payload.payload.id);
      const result = apiResult;
      yield put({
        type: actions.NEW_RESETTING_MERCHANT_USER_PASSWORD,
      });
      if (result.success) {
        yield put({
          type: actions.NEW_MERCHANT_USER_PASSWORD_RESET_SUCCESS,
          result: result ? result.data : null,
        });
      } else {
        yield put({
          type: actions.NEW_MERCHANT_USER_PASSWORD_RESET_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.NEW_MERCHANT_USER_PASSWORD_RESET_ERROR,
      });
    }
  });
}

export function* resetTokenData() {
  yield takeEvery('RESET_TOKEN_DATA', function* (payload) {
    try {
      const apiResult = yield resettingTokenData(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.RESETTING_TOKEN_DATA,
      });
      if (result.success) {
        yield put({
          type: actions.RESET_TOKEN_DATA_SUCCESS,
          result: result ? result.data : null,
        });
      } else {
        yield put({
          type: actions.RESET_TOKEN_DATA_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.RESET_TOKEN_DATA_FAILED,
      });
    }
  });
}

function fetchMerchantByMid(mid) {
  return get(`merchants/get_by_mid/${mid}`);
}
// function fetchMerchantByMidAny(mid) {
//   return get(`merchants/get_by_mid_any/${mid}`);
// }
function fetchMerchantById(id) {
  return get(`merchants/${id}`);
}
function updateMerchantDetails(data) {
  return PUT(`merchants/update/merchant_details/${data.payload.merchantData.merchantId}`, data.payload);
}
function editMerchantChannels(data) {
  return PUT(`merchantchannels/update_merchant_channels/${data.payload.merchantData.merchantId}`, data.payload.pchannels);
}
function fetchImageRequest(img) {
  return get(`merchants/get_merchant_image/${img}`);
}
function fetchStoreChannels(id) {
  return get(`storechannels/get_pchannel_redirect_payment/${id}`);
}
function fetchStoreInformation(id) {
  return get(`stores/get_store/${id}`);
}
function fetchAllPchannels() {
  return get(`pchannels/get_all_available_pchannels`);
}
function fetchPchannels(mid) {
  return get(`merchantchannels/get_pchannels_by_mid/${mid}`);
}

function fetchWithdrawalAccounts(mid) {
  return get(`merchantwithdrawalaccounts/fetch_by_mid/${mid}`);
}
function checkVerified(id) {
  return get(`merchants/check_verified/${id}`);
}
function getCustomerData(id) {
  return get(`customersapptokens/get_data_by_id/${id}`);
}
function fetchStoreImg(id) {
  return get(`stores/get_file_name_by_store_id/${id}`);
}

function fetchMerchantUsersRequest(merchantIdentity, store, payload) {
  const newData = {
    mid: payload && payload.mid ? payload.mid : merchantIdentity.merchantId,
    role: merchantIdentity.role.role.label,
    store: store.length > 0 ? store[0].storeId : '',
    authId: merchantIdentity.Id,
  };
  // return post(`users/get_all_users/new/`, newData);
  return get(`users/get_all/new/`, newData, payload.page, null, null);
}
function fetchMerchantUsersRequestWhiteLabel(merchantIdentity, store, payload, mids) {
  const newData = {
    mid: payload && payload.mid ? payload.mid : merchantIdentity.merchantId,
    role: merchantIdentity.role.role.label,
    store: store.length > 0 ? store[0].storeId : '',
    authId: merchantIdentity.Id,
    mids,
  };
  // return post(`users/get_all_users/new/`, newData);
  return get(`users/get_all/new/`, newData, payload.page, null, null);
}
function updateMerchantUserRequest(data, merchantData) {
  data['lastUpdatedBy'] = merchantData.Id;
  return post(`authenticate/update_user/new`, data);
}
function addMerchantUserRequest(data, merchantData) {
  data['createdBy'] = merchantData.Id;
  return post(`authenticate/add_user/new`, data);
}
function fetchAllFilteredRoleRequest() {
  return get(`roles/fetch/user_management_roles`);
}
function deactivateMerchantUserRequest(id, merchantData) {
const data ={
  userId: id,
  deletedBy: merchantData.Id,
}
  return post(`authenticate/change_status/new/`, data);
}
function resetMerchantUserPasswordRequest(id) {
  return get(`authenticate/reset_password/${id}`);
}
function generateData(data) {
  return post(`merchants/generate_data`, data);
}
function generateStoreData(data) {
  return post(`payment_transactions/generate_data`, data);
}
function fetchRiskProfileRequest() {
  return get(`riskprofiles/fetch/risk_profile/`);
}
function fetchRiskProfileRequestAdmin(mid) {
  return get(`riskprofiles/fetch/risk_profile/${mid}`);
}
function fetchKycQualified(mid) {
  return get(`kycqualifieds/fetch/by_mid/${mid}`);
}
function postKycDocs(data) {
  return post(`kycapplications/upload_merchant_documents/`, data);
}
function updateKycDocs(data) {
  return PUT(`kycdocs/update/kyc_docs/${data.merchantId}`, data);
}
function fetchKycRequirements() {
  return get(`kycrequirements/fetch/kyc_docs/`);
}
function getMerchantDocs(id) {
  return get(`kycapplications/get_merchant_document/${id}`);
}
function fetchMerchantReferralLinkRequest(id) {
  return get(`merchantreferrallinks/fetch_by_referral_code/${id}`);
}
function uploadMerchLogo(data) {
  return post(`merchants/upload_merchant_logo/`, data);
}
function requestReupload(mid, data) {
  return PUT(`kycdocs/request/reupload/kyc_docs/${mid}`, data);
}
function requestApprove(mid) {
  const data = {
    merchantId: mid,
  }
  return post(`kycapplications/approve_merchant_documents/`, data);
}
function payTransactionRequest(data) {
  return post(`payment_transactions/`, data);
}
function newResetMerchantUserPasswordRequest(data, token) {
  return PUT(`authenticate/new_reset_password/${token}`, data);
}
function resettingTokenData(id) {
  return get(`resettokens/get_data_by_token/${id}`);
}
export default function* rootSaga() {
  yield all([
    fork(loadMerchantInfo),
    fork(loadPchannels),
    fork(fetchMerchantUsers),
    fork(updateMerchantUser),
    fork(addMerchantUser),
    fork(fetchAllFilteredRole),
    fork(deactivateMerchantUser),
    fork(resetMerchantUserPassword),
    fork(getAllPchannels),
    fork(editMerchantDetails),
    fork(downloadHistory),
    fork(downloadStoreTxn),
    fork(fetchRiskProfile),
    fork(getKycQualified),
    fork(submitKycDocs),
    fork(previewKycDocs),
    fork(reloadKyc),
    fork(fetchMerchantReferralLink),
    fork(askForReupload),
    fork(approveKycDocs),
    fork(uploadLogo),
    fork(updateMerchantChannels),
    fork(fetchMerchantImage),
    fork(paymentTransactionRequest),
    fork(fetchCustomerData),
    fork(resetTokenData),
    fork(newResetMerchantUserPassword),
    // fork(mqttLoginValidator),
  ]);
}
