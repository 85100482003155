import actions from './actions';
import authActions from '../auth/actions';
// import dummyInventory from './dummyData'

const initState = {
  errorMessage: null,
  message: null,
  loadingMoreInventory: false,
  currentInventory: null,
  inventory: null,
  inventoryLoading: false,
  scrollId: null,
  moduleTitle: null,
  moduleIcon: null,
  // FETCHING ES TRX
  inventoryCount: 0,
  view: null,
  inventoryErrorMessage: null,
  storeInventory: null,
  allowedPchannels: null,
  transactionFees: {
    nonbank_otc: {
      percent: 0.025,
      peso: 25.00
    },
    wallet: {
      percent: 0.025,
      peso: 0.00
    },
    onlinebanktransfer: {
      percent: 0.025,
      peso: 0.00
    },
    bank_otc: {
      percent: 0.025,
      peso: 25.00
    },
  },
  inventoryLogs: null,
  logsLoading: false,
  addDialogVisible: false,
  itemSaving: false,
  itemSaved: false,
  itemSuccess: false,
  addErrorMessage: '',
  addEventTriggered: false,
  addEventLabel: null,
  addEventType: null,
  addEventAction: null,
  showAddMessage: false,
  itemReplenishing: false,
  itemReplenished: false,
  replenishSuccess: false,
  itemEditing: false,
  itemEdited: false,
  editSuccess: false,
  itemDeleting: false,
  itemDeleted: false,
  deleteSuccess: false,
  getQrImageBoolean: false,
  qrImage: null,
  // showAddMessage: null,
  isDownloadQrPdf: false,
  downloadedQrPdf: null,
  redirectProductFetching: false,
  redirectProduct: null,
  redirectProductMessage: null,
  productUrl: null,
  errorMessageAddProduct: null,
};

export default (state = initState, action) => {

  switch (action.type) {

    case actions.LOAD_INVENTORY:
      return initState;
    case actions.LOADING_INVENTORY:
      return {
        ...state,
        inventoryLoading: true,
      };
    case actions.LOADING_MORE_INVENTORY:
      return {
        ...state,
        loadingMoreInventory: true,
      };
    case actions.INVENTORY_LOADED:
      const trx = action.inventory ? {
        ...state.inventory,
        data: action.inventory,
      } : null;
      return {
        ...state,
        loadingMoreInventory: false,
        inventoryLoading: false,
        scrollId: action.inventory ? action.scrollId : null,
        inventory: trx,
        inventoryCount: action.inventoryCount,
      };
    case actions.LOAD_INVENTORY_FAILED:
      return {
        ...state,
        loadingMoreInventory: false,
        inventoryLoading: false,
      };
    case actions.SET_INV_TITLE:
      return {
        ...state,
        moduleTitle: action.payload.title,
        moduleIcon: action.payload.icon,
      };
    case actions.SET_CURRENT_INVENTORY_ITEM:
      return {
        ...state,
        currentInventory: action.payload,
        qrImage: null,
      };
    case actions.CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        errorMessage: null,
        tagErrorMessage: null,
        folderErrorMessage: null,
      };

    case authActions.LOGOUT:
      // reset all redux param values
      return initState;
    case actions.CLEAR_INVENTORY:
      return {
        ...state,
        inventory: null,
        scrollId: null,
        inventoryLoading: false,
        loadingPendingTrx: false,
        loadingRefundedTrx: false,
        loadingFailedTrx: false,
        loadingPaidTrx: false,
      };
    case actions.CLEAR_INVENTORY_ONLY:
      return {
        ...state,
        inventory: null,
        currentInventory: null,
      };
    case actions.LOADING_STORE_INVENTORY:
      return {
        ...state,
        inventoryLoading: true,
        loadingStoreAssets: true,
      };
    case actions.STORE_INVENTORY_LOADED:
      return {
        ...state,
        inventoryLoading: false,
        storeInventory: action.inventory,
      };
    case actions.RESET_STORE_INVENTORY:
      return {
        ...state,
        inventoryLoading: false,
        storeInventory: null,
      };
    case actions.LOAD_STORE_INVENTORY_FAILED:
      return {
        ...state,
        inventoryLoading: false,
        loadingStoreAssets: false,
        storeInventory: null,
        inventoryErrorMessage: '', // payload err here
      };
    case actions.SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case actions.LOADING_ALLOWED_PCHANNEL:
      return {
        ...state,
      };
    case actions.ALLOWED_PCHANNEL_LOADED:
      return {
        ...state,
        inventoryLoading: false,
        allowedPchannels: action.pchannels,
      };
    case actions.RESET_ALLOWED_PCHANNEL:
      return {
        ...state,
        allowedPchannels: null,
      };
    case actions.LOAD_ALLOWED_PCHANNEL_FAILED:
      return {
        ...state,
        inventoryLoading: false,
        allowedPchannels: null,
        inventoryErrorMessage: '', // payload err here
      };
    case actions.LOADING_INVENTORY_LOGS:
      return {
        ...state,
        logsLoading: true,
      };
    case actions.INVENTORY_LOGS_LOADED:
      return {
        ...state,
        logsLoading: false,
        inventoryLogs: action.inventoryLogs,
      };
    case actions.LOAD_INVENTORY_LOGS_FAILED:
      return {
        ...state,
        logsLoading: false,
      };
    case actions.RESET_INVENTORY_LOGS:
      return {
        ...state,
        logsLoading: false,
        inventoryLogs: null,
      };
    case actions.TOGGLE_ADD_DIALOG:
      return {
        ...state,
        addDialogVisible: !state.addDialogVisible,
      };
    case actions.ITEM_SAVING:
      return {
        ...state,
        itemSaving: true,
        addDialogVisible: true,
      };
    case actions.ITEM_ADDED:
      return {
        // ...state,
        addDialogVisible: false,
        itemSaving: false,
        itemSaved: action.success ? false : true,
        itemSuccess: action.success ? false : true,
        // customFolders: [
        //   ...state.customFolders,
        //   {
        //     id: action.id,
        //     label: action.label,
        //     icon: 'Folder'
        //   },
        // ],
      };
    case actions.RESET_ITEM_SAVED:
      return {
        ...state,
        itemSaved: false,
        itemSuccess: false,
      };
    case actions.ITEM_ADD_FAILED:
      return {
        ...state,
        itemSaving: false,
        // addDialogVisible: false,
        // addErrorMessage: 'Error adding product, please try again later',
      };
    case actions.SHOW_ADD_EVENT_MESSAGE:
      return {
        ...state,
        showAddMessage: action.payload,
      };
    case actions.SHOW_ERROR_ADD_PRODUCT:
      return {
        ...state,
        errorMessageAddProduct: action.payload,
      };
    case actions.ADD_EVENT_TRIGGERING:
      return {
        ...state,
        addEventTriggered: action.triggering !== undefined ? action.triggering : action.payload,
      };
    case actions.ADD_EVENT_MESSAGE:
      return {
        ...state,
        addEventLabel: action.payload.label,
        addEventType: action.payload.type,
        addEventAction: action.payload.action,
      };
    case actions.ITEM_REPLENISHING:
      return {
        ...state,
        itemReplenishing: true,
      };
    case actions.ITEM_REPLENISHED:
      const replenishedInventory = action.data;
      const listInventory = state.inventory;
      let replenishedSelectedInv;
      for (let i = 0; i < listInventory.data.length; i++) {
        const item = listInventory.data[i];
        if (replenishedInventory.Id === item.Id) {
          listInventory.data[i].quantity = replenishedInventory.quantity;
          replenishedSelectedInv = listInventory.data[i];
        }
      }
      Object.assign(state.currentInventory, replenishedSelectedInv);
      return {
        ...state,
        inventory: listInventory,
        currentInventory: {
          ...state.currentInventory,
          replenishedSelectedInv,
        },
        itemReplenishing: false,
        itemReplenished: action.success ? false : true,
        replenishSuccess: action.success ? false : true,
      };
    case actions.RESET_ITEM_REPLENISHED:
      return {
        ...state,
        itemReplenished: false,
        replenishSuccess: false,
      };
    case actions.ITEM_REPLENISH_FAILED:
      return {
        ...state,
        itemReplenishing: false,
      };
    case actions.ITEM_EDITING:
      return {
        ...state,
        itemEditing: true,
      };
    case actions.ITEM_EDITED:
      const updatedInventory = action.data;
      const inventoryList = state.inventory;
      let updatedSelectedInv;
      for (let i = 0; i < inventoryList.data.length; i++) {
        const product = inventoryList.data[i];
        if (updatedInventory.Id === product.Id) {
          inventoryList.data[i].itemDescription = updatedInventory.itemDescription;
          inventoryList.data[i].itemCode = updatedInventory.itemCode;
          inventoryList.data[i].itemAmount = updatedInventory.itemAmount;
          inventoryList.data[i].paymentHeader = updatedInventory.paymentHeader;
          inventoryList.data[i].additionalCharge = updatedInventory.additionalCharge;
          inventoryList.data[i].isUnlimited = updatedInventory.isUnlimited;
          inventoryList.data[i].quantity = updatedInventory.quantity;
          inventoryList.data[i].status = updatedInventory.status;
          inventoryList.data[i].statusInfo.Id = updatedInventory.status;
          inventoryList.data[i].statusInfo.label = updatedInventory.statusLabel;
          updatedSelectedInv = inventoryList.data[i];
        }
      }
      Object.assign(state.currentInventory, updatedSelectedInv);
      return {
        ...state,
        inventory: inventoryList,
        currentInventory: {
          ...state.currentInventory,
          updatedSelectedInv,
        },
        itemEditing: false,
        itemEdited: action.success ? false : true,
        editSuccess: action.success ? false : true,
      };
    case actions.RESET_ITEM_EDITED:
      return {
        ...state,
        itemEdited: false,
        editSuccess: false,
      };
    case actions.ITEM_EDIT_FAILED:
      return {
        ...state,
        itemEditing: false,
      };
    case actions.ITEM_DELETING:
      return {
        ...state,
        itemDeleting: true,
      };
    case actions.ITEM_DELETED:
      return {
        ...state,
        itemDeleting: false,
        inventory: action.inventory,
        currentInventory: null,
        itemDeleted: action.success ? false : true,
        deleteSuccess: action.success ? false : true,
      };
    case actions.RESET_ITEM_DELETED:
      return {
        ...state,
        itemDeleted: false,
        deleteSuccess: false,
      };
    case actions.ITEM_DELETING_FAILED:
      return {
        ...state,
        itemDeleting: false,
      };
    case actions.GETTING_QR_IMAGE:
      return {
        ...state,
        getQrImageBoolean: action.payload,
      };
    case actions.GET_QR_IMAGE_FAILED:
      return {
        ...state,
        getQrImageBoolean: false,
      };
    case actions.QR_IMAGE:
      return {
        ...state,
        qrImage: action.payload,
        productUrl: action.productUrl,
      };
    case actions.DOWNLOADING_QR_PDF:
      return {
        ...state,
        isDownloadQrPdf: action.payload,
      };
    case actions.DOWNLOADED_QR_PDF:
      return {
        ...state,
        downloadedQrPdf: action.payload,
      };
    case actions.RESET_DOWNLOADED_QR_PDF:
      return {
        ...state,
        downloadedQrPdf: null,
      };
    case actions.DOWNLOAD_QR_PDF_FAILED:
      return {
        ...state,
        isDownloadQrPdf: false,
      };
    case actions.FETCHING_PRODUCT_BY_ID:
      return {
        ...state,
        redirectProductFetching: true,
      };
    case actions.FETCHED_PRODUCT_BY_ID:
      return {
        ...state,
        redirectProductFetching: false,
        redirectProduct: action.payload,
      };
    case actions.FETCH_PRODUCT_BY_ID_FAILED:
      return {
        ...state,
        redirectProductFetching: false,
        redirectProduct: null,
        redirectProductMessage: action.payload,
      };
    default:
      return state;
  }
}