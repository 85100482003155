import { all, takeEvery, put, select } from 'redux-saga/effects';
import actions from './actions';
import { get, post } from '@iso/lib/helpers/resApiRequestor';

export function* loadPaymentCount() {
  yield takeEvery('LOAD_DASHBOARD_DATA', function* () {
    try {
      const state = yield select();
      const role = state.Auth.identity.role.role.label;
      const merchantId = role !== 'Administrator' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
      const loading = state.Dashboard.isLoadingDashboard;
      if (!loading) {
        yield put({
          type: actions.LOADING_DASHBOARD_DATA,
          payload: true,
        });
        // const apiResult = yield getDashboardPaymentCount();
        const apiResult = yield getDashboardPaymentCount(merchantId);
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.LOADING_DASHBOARD_DATA,
            payload: false,

          });
          if (result.success) {
            yield put({
              type: actions.LOADED_DASHBOARD_DATA,
              payload: result.data,
            });
          }
        } else {
          yield put({
            type: actions.LOAD_DASHBOARD_ERROR,
            payload: true,
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.LOAD_DASHBOARD_ERROR,
        payload: true,
      });
    }
  });
}
export function* getWinners() {
  yield takeEvery('GET_WINNERS', function* ({ payload }) {
    try {
      yield put({
        type: actions.GETTING_WINNERS,
        payload: true,
      });
      const apiResult = yield checkWinners();
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.GOT_WINNERS,
          payload: result.message,
          success: true,
        });
      } else {
        yield put({
          type: actions.GOT_WINNERS,
          payload: result.message,
          success: false,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SENDING_FAILED_RAFFLE_ENTRY,
      });
    }
  });
}
export function* startRaffle() {
  yield takeEvery('START_RAFFLE', function* () {
    try {
      yield put({
        type: actions.STARTING_RAFFLE,
        payload: true,
      });
      const apiResult = yield startingDraw();
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.DONE_RAFFLE,
          payload: result.data.length > 0 ? result.data[0] : {name: 'No Selected Winner'},
          success: true,
        });
        yield put({
          type: actions.GOT_WINNERS,
          payload: result.data.length > 0 ? result.data[0] : 'No Winner',
        });
      }
    } catch (error) {
      yield put({
        type: actions.STARTING_RAFFLE,
        payload: false,
      });
    }
  });
}
export function* getWinner() {
  yield takeEvery('GET_WINNERS', function* () {
    try {
      yield put({
        type: actions.GETTING_WINNERS,
        payload: true,
      });
      const apiResult = yield gettingWinner();
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.GOT_WINNERS,
          payload: result.data.length > 0 ? result.data[0] : 'No Winner',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_WINNERS,
        payload: false,
      });
    }
  });
}
export function* sendRaffleEntry() {
  yield takeEvery('SEND_RAFFLE_ENTRY', function* ({ payload }) {
    try {
      yield put({
        type: actions.SENDING_RAFFLE_ENTRY,
        payload: true,
      });
      const apiResult = yield sendingRaffleEntry(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.RAFFLE_ENTRY,
          payload: result.message,
          success: true,
        });
      } else {
        yield put({
          type: actions.RAFFLE_ENTRY,
          payload: result.message,
          success: false,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SENDING_FAILED_RAFFLE_ENTRY,
      });
    }
  });
}

function getDashboardPaymentCount(mid) {
  return get(`dashboard/get_dashboard_data/${mid}`);
}
function sendingRaffleEntry(payload) {
  return post(`raffleentries/`, payload);
}
function checkWinners() {
  return get(`raffleentries/get_winners/`);
}
function startingDraw() {
  return get(`raffleentries/draw_raffle/`);
}
function gettingWinner() {
  return get(`raffleentries/get_winners/`);
}
export default function* rootSaga() {
  yield all([
    loadPaymentCount(),
    sendRaffleEntry(),
    checkWinners(),
    startRaffle(),
    getWinner(),
  ]);
}
