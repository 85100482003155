import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import { get, post, postBlob } from '@iso/lib/helpers/resApiRequestor';
import * as _ from 'lodash';

export function* loadAllInventory() {
  yield takeEvery('LOAD_ALL_INVENTORY', function* ({ payload }) {
    const state = yield select();
    const loadingInventory = state.Inventory.inventoryLoading;
    if (!loadingInventory) {
      try {
        yield put({
          type: actions.LOADING_INVENTORY,
        });
        yield put({
          type: actions.RESET_ALLOWED_PCHANNEL
        });
        const result = yield fetchInventoryByStore(payload.storeId);
        if (result.data.success) {
          yield put({
            type: actions.INVENTORY_LOADED,
            inventory: result.data.data,
            scrollId: null,
            inventoryCount: 0,
          });
          try {
            yield put({
              type: actions.LOADING_ALLOWED_PCHANNEL
            });
            const apiResult = yield fetchAllowedChannels(payload.storeId);
            const result = apiResult.data;
            yield put({
              type: actions.ALLOWED_PCHANNEL_LOADED,
              pchannels: result.data.length > 0 ? result.data : [],
            });
          } catch (err) {
            yield put({
              type: actions.LOAD_ALLOWED_PCHANNEL_FAILED,
            });
          }
        }
      } catch (error) {
        yield put({
          type: actions.LOAD_INVENTORY_FAILED,
        });
      }
    }
  });
}

export function* loadInventoryLogs() {
  yield takeEvery('LOAD_INVENTORY_LOGS', function* ({ payload }) {
    const state = yield select();
    const logsLoading = state.Inventory.logsLoading;
    if (!logsLoading) {
      try {
        yield put({
          type: actions.LOADING_INVENTORY_LOGS,
        });
        const result = yield fetchInventoryLogs(payload.storeId, payload.itemCode);
        if (result.data.success) {
          yield put({
            type: actions.INVENTORY_LOGS_LOADED,
            inventoryLogs: result.data.data,
          });
        }
      } catch (error) {
        yield put({
          type: actions.LOAD_INVENTORY_LOGS_FAILED,
        });
      }
    }
  });
}

export function* addItem() {
  yield takeEvery('ADD_ITEM', function* ({ payload }) {
    try {
      yield put({
        type: actions.ITEM_SAVING,
      });
      const apiResult = yield saveItem(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.ITEM_ADDED,
          result: result.success,
        });
        yield put({
          type: actions.ADD_EVENT_TRIGGERING,
          triggering: false,
        });
      } else {
        yield put({
          type: actions.ITEM_ADD_FAILED,
        });
        yield put({
          type: actions.SHOW_ERROR_ADD_PRODUCT,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ITEM_ADD_FAILED,
      });
    }
  });
}

export function* replenishItem() {
  yield takeEvery('REPLENISH_ITEM', function* ({ payload }) {
    try {
      yield put({
        type: actions.ITEM_REPLENISHING,
      });
      const apiResult = yield updateItem(payload);
      const result = apiResult.data;
      yield put({
        type: actions.ITEM_REPLENISHED,
        result: result.success,
        data: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.ITEM_REPLENISH_FAILED,
      });
    }
  });
}

export function* editItem() {
  yield takeEvery('EDIT_ITEM', function* ({ payload }) {
    try {
      yield put({
        type: actions.ITEM_EDITING,
      });
      const apiResult = yield updateItem(payload);
      const result = apiResult.data;
      yield put({
        type: actions.ITEM_EDITED,
        result: result.success,
        data: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.ITEM_EDIT_FAILED,
      });
    }
  });
}

export function* deleteItem() {
  yield takeEvery('DELETE_ITEM', function* ({ payload }) {
    const state = yield select();
    try {
      yield put({
        type: actions.ITEM_DELETING,
      });
      const apiResult = yield removeItem(payload);
      const result = apiResult.data;
      const inventory = state.Inventory.inventory;
      inventory.data = _.reject(inventory.data, { itemCode: payload.itemCode });
      yield put({
        type: actions.ITEM_DELETED,
        result: result.success,
        inventory: inventory,
      });
    } catch (error) {
      yield put({
        type: actions.ITEM_DELETING_FAILED,
      });
    }
  });
}
export function* getQrImage() {
  yield takeEvery('GET_QR_IMAGE', function* ({ payload }) {
    try {
      yield put({
        type: actions.GETTING_QR_IMAGE,
        payload: true,
      });
      const apiResult = yield getQr(payload.Id);
      const result = apiResult.data;
      yield put({
        type: actions.GETTING_QR_IMAGE,
        payload: false,
      });
      if (result.success) {
        yield put({
          type: actions.QR_IMAGE,
          payload: result.data, // put the base64 image here
          productUrl: result.url,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_QR_IMAGE_FAILED,
      });
    }
  });
}
export function* downloadQrPdf() {
  yield takeEvery('DOWNLOAD_QR_PDF', function* ({ payload }) {
    try {
      yield put({
        type: actions.DOWNLOADING_QR_PDF,
        payload: true,
      });
      const apiResult = yield generateQr({
        itemId: payload.Id,
        itemName: payload.itemDescription,
      });
      const result = apiResult.data;
      yield put({
        type: actions.DOWNLOADING_QR_PDF,
        payload: false,
      });
      if (result) {
        // const dlApiResult = yield downloadQr({ filename: result.data });
        // const dlResult = dlApiResult.data;
        yield put({
          type: actions.DOWNLOADED_QR_PDF,
          payload: result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_QR_PDF_FAILED,
      });
    }
  });
}
export function* fetchProductById() {
  yield takeEvery('FETCH_PRODUCT_BY_ID', function* ({ payload }) {
    try {
      yield put({
        type: actions.FETCHING_PRODUCT_BY_ID,
      });
      const result = yield fetchInvById(payload);
      if (result.status === 200) {
        if (result.data.success) {
          yield put({
            type: actions.FETCHED_PRODUCT_BY_ID,
            payload: result.data.data,
          });
        } else {
          yield put({
            type: actions.FETCH_PRODUCT_BY_ID_FAILED,
            payload: result.data.message,
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PRODUCT_BY_ID_FAILED,
      });
    }
  });
}
function fetchInvById(id) {
  return get(`inventories/fetch_by_id/${id}`);
}
function generateQr(data) {
  return postBlob(`inventories/download_product_qr`, data);
}
// function downloadQr(data) {
//   return postBlob(`stores/get_pdf`, data);
// }
function fetchInventoryByStore(id) {
  return get(`inventories/get_product/ui/${id}`);
}
function getQr(id) {
  return get(`inventories/get_product_qr/${id}`);
}
function fetchAllowedChannels(storeId) {
  return get(`storechannels/get_pchannel_by_store_id/${storeId}`);
}
function fetchInventoryLogs(storeId, itemCode) {
  const data = {
    storeId,
    itemCode,
  }
  return post(`inventorylogs/find_by_item_code`, data);
}
function saveItem(data) {
  return post(`inventories/create_product_app`, data);
}
function updateItem(data) {
  return post(`inventories/update_product_app`, data);
}
function removeItem(data) {
  return post(`inventories/delete_product_app`, data);
}

export default function* rootSaga() {
  yield all([
    fork(loadAllInventory),
    fork(loadInventoryLogs),
    fork(addItem),
    fork(replenishItem),
    fork(editItem),
    fork(deleteItem),
    fork(getQrImage),
    fork(downloadQrPdf),
    fork(fetchProductById),
  ]);
}